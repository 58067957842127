/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = 'https://apilambo.swinor.com/';
axios.defaults.params = {}
axios.defaults.params['adminid'] = localStorage.getItem('adminid');
axios.defaults.params['admintoken'] = localStorage.getItem('admintoken');


axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  console.log(config);
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  if(response.data.sessionstatus && response.data.sessionstatus!==true) {
    localStorage.removeItem("admintoken");
    localStorage.removeItem("adminid");
    // location.reload();
  }
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});

//
// import Popper from "vue3-popper";


import directives from "@/directives";
import PrimeVue from "primevue/config";
import Vuesax from 'vuesax3'
import 'material-icons/iconfont/material-icons.css';
import 'vuesax3/dist/vuesax.css'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import ProfileModal from "../src/views/components/ProfileModal";

const appInstance = createApp(App);
directives(appInstance);
// appInstance.component("Popper", Popper)
appInstance.use(VueAxios, axios)
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(PrimeVue)
appInstance.use(Vuesax);
appInstance.use("ProfileModal",ProfileModal);
// appInstance.component("PrimeBtn", Button);

appInstance.mount("#app");
