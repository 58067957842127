<template>
  <div v-if="data">
    <div class="card-header pb-0">
      <h6>Smulators</h6>
    </div>
    <div style="display: flex;flex-wrap: wrap;gap:20px; padding-right: 20px;">
      <div v-for="(d,index) in data.simulator" style="padding: 20px 0;display: flex;flex-direction: column;border: 1px solid black;padding:15px;border-radius: 10px;background-color: #e5e5e5;" >
        <h5>{{d.name}}</h5>
        <vs-input placeholder="ID" v-model="d.userid" style="margin-bottom: 10px;width:100%;"/>
        <vs-input placeholder="Value" v-model="d.number"  v-if="d.numbers_needed" style="margin-top: 10px;margin-bottom: 10px;"/>
        <vs-button style="margin-left: auto;margin-right:auto;margin-top: 10px;margin-top: auto" color="dark" type="filled" @click="simulateAction(d.simulationtype,d.number, d.userid,  index)">Submit</vs-button>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  data(){
    return {
      data:null,
      v_id:"",
      m_id:"",
      f_id:"",
      v_number:"",
      m_number:"",
      f_number:"",
    }
  },
  methods: {
    simulateAction(action, number, id,index){
      this.axios.get(`/simulators?simulationtype=${action}&number=${number}&userid=${id}`).then((response) => {
        console.log(response);
        this.v_id=""
         this.m_id=""
          this.f_id=""
          this.v_number=""
          this.m_number=""
          this.f_number=""
        this.data.simulator[index].userid = "";
        this.data.simulator[index].number = "";

        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    getData(){
      this.axios.get("/simulators").then((response) => {
        console.log(response);
        this.data = response.data;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>