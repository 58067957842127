<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
    style="height:75vh!important;"
  >
    <ul class="navbar-nav" style="height:75vh!important;">
      <li class="nav-item" @click="reload('Dashboard')">
        <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
          <template #icon>
            <ion-icon name="home"></ion-icon>
          </template>
        </sidenav-collapse>
      </li>
<!--      <li class="nav-item">-->
<!--        <sidenav-collapse navText="Price update" :to="{ name: 'Tools' }">-->
<!--          <template #icon>-->
<!--            <credit-card  />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->
      <li class="nav-item">
        <sidenav-collapse navText="Users" :to="{ name: 'Users' }" @click="reload('Users')">
          <template #icon>
            <ion-icon name="people"></ion-icon>

          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Simulators" :to="{ name: 'Simulator' }" @click="reload('Simulator')">
          <template #icon>
            <ion-icon name="git-compare"></ion-icon>

          </template>
        </sidenav-collapse>
      </li>


<!--      <li class="nav-item">-->
<!--        <sidenav-collapse navText="Tables" :to="{ name: 'Tables' }">-->
<!--          <template #icon>-->
<!--            <office />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-collapse navText="Billing" :to="{ name: 'Billing' }">-->
<!--          <template #icon>-->
<!--            <credit-card />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->

<!--      <li class="nav-item">-->
<!--        <sidenav-collapse-->
<!--          navText="Virtual Reality"-->
<!--          :to="{ name: 'Virtual Reality' }"-->
<!--        >-->
<!--          <template #icon>-->
<!--            <box3d />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-collapse navText="RTL" :to="{ name: 'Rtl' }">-->
<!--          <template #icon>-->
<!--            <settings />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->
<!--      <li class="mt-3 nav-item">-->
<!--        <h6-->
<!--          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"-->
<!--          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"-->
<!--        >-->
<!--          PAGES-->
<!--        </h6>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-collapse navText="Profile" :to="{ name: 'Profile' }">-->
<!--          <template #icon>-->
<!--            <customer-support />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-collapse navText="Sign In" :to="{ name: 'Sign In' }">-->
<!--          <template #icon>-->
<!--            <document />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-collapse navText="Sign Up" :to="{ name: 'Sign Up' }">-->
<!--          <template #icon>-->
<!--            <spaceship />-->
<!--          </template>-->
<!--        </sidenav-collapse>-->
<!--      </li>-->
      <li class="nav-item" style="margin-top: auto!important;">
        <sidenav-collapse navText="Logout" :to="{ name: 'Logout' }">
          <template #icon>
            <ion-icon name="log-out"></ion-icon>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
<!--  <div class="pt-3 mx-3 mt-3 sidenav-footer">-->
<!--    <sidenav-card-->
<!--      :class="cardBg"-->
<!--      textPrimary="Need Help?"-->
<!--      textSecondary="Please check our docs"-->
<!--      route="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/"-->
<!--      label="Documentation"-->
<!--      icon="ni ni-diamond"-->
<!--    />-->
<!--    <a-->
<!--      class="btn bg-gradient-success mt-4 w-100"-->
<!--      href="https://www.creative-tim.com/product/vue-soft-ui-dashboard-pro?ref=vsud"-->
<!--      type="button"-->
<!--      >Upgrade to pro</a-->
<!--    >-->
<!--  </div>-->
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import Settings from "../../components/Icon/Settings.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    SidenavCard,
    Shop,
    Office,
    CreditCard,
    Box3d,
    CustomerSupport,
    Document,
    Spaceship,
    Settings,
  },
  methods: {
    reload(route){
      if(route=="Users") window.location = "/users";
      if(route=="Dashboard") window.location = "/dashboard";
      if(route=="Simulator") window.location = "/simulator";
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
