<template>
  <div></div>
</template>

<script>
export default {
  beforeMount(){
    localStorage.removeItem("adminid");
    localStorage.removeItem("admintoken");
    location.reload();
  }
}
</script>