<template>
  <div class="card mb-4" tabindex="0"  @keydown.esc="popupActivo=false">
    <div class="card-header pb-0">sss
      <h6>Contacts</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              ID
            </th>
            <th  class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >USERID</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              name
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              email
            </th>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              date
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              domain
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              solved_admin
            </th>
          </tr>
          </thead>
          <tbody>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftProgress from "@/components/SoftProgress";

export default {
  name: "projects-table",
  components: {
    SoftProgress,
  },
  data(){
    return {
      popupActivo:false,
      all_pictures:[]
    }
  },
  methods:{
    markSolved(id){
      this.axios.get(`/reports?reportid=${id}`).then((response) => {
        console.log(response);
        this.$emit('getData');
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    activatePopup(images){
      this.all_pictures = images;
      this.popupActivo=true;

    },

    copyValue(val) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(val);
    },
  },
  props:{
    contacts: {
      type:Array
    }
  }
};
</script>
