<template>
  <div style="overflow-x: hidden;padding:0 10px;" tabindex="0"  @keydown.esc="modal=false" >
    <ProfileModal :open="modal" @closeModal="modal=false" :id="idModal"/>
    <div style="display: flex;flex-direction: column;align-items: start;padding-right: 10px;">
      <vs-radio v-model="realreal" vs-name="all" vs-value="all">All</vs-radio>
      <vs-radio v-model="realreal" vs-name="Real-Real" vs-value="realreal">Real-Real</vs-radio>
      <vs-radio v-model="realreal" vs-name="Real-Sender" vs-value="realsender">Real-Sender</vs-radio>
    </div>
    <div style="display:flex;flex-direction: row;border-bottom: 1px solid black;justify-content: center;margin-top: 20px;" v-for="(m, index) in messages" :key="index">
      <div style="display: flex;flex-display:row;border-right: 1px solid black;padding-right: 20px;">
        <div style="height:200px;width:200px!important;" >
          <img :src="m.chat.sender.picture" style="height:200px;width:200px!important;" @click="modal=true,idModal=m.chat.sender.USERID"/>
        </div>
        <div style="margin-left: 20px;width:150px;">
          <p class="text-sm font-weight-bold mb-0"
          >{{m.chat.sender.USERID}}</p>
          <p class="text-sm font-weight-bold mb-0"
          >{{m.chat.sender.name}}</p>
          <p class="text-sm font-weight-bold mb-0"
          >{{m.chat.sender.city}}, {{m.chat.sender.country}}</p>
          <p class="text-sm font-weight-bold mb-0" :style="{color:m.chat.different_domains ? 'red' : ''}"
          >{{m.chat.sender.user_domain}}</p>
          <p class="text-sm font-weight-bold mb-0" style="color:red;" v-if="m.chat.sender.tbo"
          >BOT</p>
        </div>
      </div>

      <h6 class="mb-0 text-sm" style="padding-left: 20px;padding-right: 20px;width:calc(100% - 500px)">
        {{m.chat.time}}
        <br />

        {{m.chat.message}}</h6>
      <div style="display: flex;flex-display:row;border-left: 1px solid black;padding-left: 20px; ">
        <div style="margin-right: 20px;width:150px;">
          <p class="text-sm font-weight-bold mb-0"
          >{{m.chat.reciver.USERID}}</p>
          <p class="text-sm font-weight-bold mb-0"
          >{{m.chat.reciver.name}}</p>
          <p class="text-sm font-weight-bold mb-0"
          >{{m.chat.reciver.city}}, {{m.chat.reciver.country}}</p>
          <p class="text-sm font-weight-bold mb-0"
             :style="{color:m.chat.different_domains ? 'red' : ''}"
          >{{m.chat.reciver.user_domain}}</p>
          <p class="text-sm font-weight-bold mb-0" style="color:red;font-size: 30px;" v-if="m.chat.reciver.tbo"
          ><img src="../assets/img/bot.png" style="width:70px;"/></p>
        </div>
        <div style="height:200px;width:200px!important;" >
          <img :src="m.chat.reciver.picture" style="height:200px;width:200px!important;"  @click="modal=true,idModal=m.chat.reciver.USERID"/>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import ProfileModal from "@/views/components/ProfileModal";
export default  {
  components: { ProfileModal },
  data(){
  return {
      messages:[], modal:false, idModal:0,
    realreal:"all",
    realsender:null
  }
},
  methods:{
    getData(){
      this.axios.get(`/messages?realreal=${this.realreal=='realreal' ? 1 : 0}&realsender=${this.realreal=='realsender' ? 1 : 0}`).then((response) => {
        console.log(response);
        this.messages = response.data.content;

        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    }
  },
  watch:{
    realreal(){
      this.getData();
    }
  },
  beforeMount() {
    this.getData()
  },
  mounted: function () {
    window.setInterval(() => {
      this.getData()
    }, 10000)
  }
}
</script>