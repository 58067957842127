<template>
  <div >
    <div style="display: flex;flex-direction: column;cursor: pointer;" @click="reloadPage">
      <!-- <p class="font-bold text-white text-lg">{{ currentTime.toLocaleString() }}</p> -->
      <p class="text-sm font-weight-bold mb-0" style="font-size: 30px!important;">{{ currentTime.toLocaleTimeString() }}</p>
      <p class="text-sm font-weight-bold mb-0" style="margin-top: -6px;margin-left: 4px;">{{ currentTime.toLocaleDateString() }}</p>
    </div>
  </div>
</template>
<script>
import { useCurrentTime } from "./useCurrentTime";
export default {
  name: "CurrentTimeExample",
  setup() {
    const { currentTime } = useCurrentTime();
    console.log(currentTime.value);
    return { currentTime };
  },
  methods:{
    reloadPage(){
      console.log(this.$route)
      if(this.$route.fullPath == '/dashboard') location.reload();else
      this.$router.push("/");
    }
  }
};
</script>