<template lang="html">
  <div class="centerx">
    <vs-popup class="holamundo"  title="Lorem ipsum dolor sit amet" :active.sync="open" @close="$emit('closeModal')">
      <vs-textarea label="Label in Textarea" v-model="message" />
      <vs-button color="primary" type="filled" @click="sendMessage">Send</vs-button>
    </vs-popup>
  </div>
</template>

<script>
export default {
  props:{
    open:{
      type:Boolean,
      default:false
    },
    id:{
      type:Number,
      default:""
    }
  },
  data(){
    return {
      message:""
    }
  },
  methods:{
    async sendMessage() {
      this.axios.get(`/sendadminmessage?sendadmintouserid=${this.id}&adminmessage=${this.message}`).then((response) => {
        console.log(response);
        this.message = "";
        this.$emit("closeModal");
      })

    }
  }
}
</script>