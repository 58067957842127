<template>
  <div>
    <span v-if="err.length == 0">
      No results
    </span>
    <div v-else>
      <p v-for="(e, index) in err" :key="index">{{e}}</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      err:[]
    }
  },
  beforeMount() {
    this.axios.get(`/phperrors`).then((response) => {
      console.log(response);
      this.err= response.data.errors;
    })
  }
}
</script>