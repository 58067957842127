<template>
  <div>
    <PricesTable :prices="prices"/>
    <!--    <div v-for="u in users" :key="u.USERID">{{u}}</div>-->
  </div>
</template>

<script>

import PricesTable from "@/views/components/PricesTable";
export default {
  components:{
    PricesTable,

  },
  data(){
    return {
      prices:[]
    }
  },
  methods:{
    getData(){
      this.axios.get("/discountprices").then((response) => {
        console.log(response);
        this.prices = response.data.prices;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>