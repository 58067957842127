<template>
  <div>
    <ReportsTable :users="users" :total="number" @getData="getData"/>
  </div>
</template>


<script>
import ReportsTable from "@/views/components/ReportsTable";

export default  {
  components:{
    ReportsTable
  },
  data(){
    return {
        users:[], number:0
    }
  },
  methods: {
    getData(){
      this.axios.get(`/reports`).then((response) => {
        console.log(response);
        this.users = response.data.reports;
        this.number = response.data.total_unsolved;
        console.log(response.data.reports);
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },

  },
  beforeMount() {
    this.getData();
  }
}
</script>