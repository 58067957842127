<template>
  <div v-if="!!main">
    <b>Max possible: {{ main.maxpossible }}</b>
    <div style="display:flex;gap:10px">
      <vs-input class="inputx" placeholder="From" type="number" v-model="fromValue"/>
      <vs-input class="inputx" placeholder="To" type="number" v-model="toValue"/>
      <vs-button color="dark" type="filled" @click="sendData">Submit</vs-button>
      <a href="/list-videos">        <vs-button color="warning" type="filled" >List videos</vs-button>
      </a>
    </div>
    <div v-if="!!main.download_link">
      <b>{{main.download_info}}</b>
      <br/>
      <a :href="main.download_link" target="_blank">
        <vs-button color="dark" type="filled" @click="sendData">Download</vs-button>
      </a>
    </div>
    <div>
      <b>Get video description</b>
      <vs-input class="inputx" placeholder="Video name"  v-model="videoID"/>
      <div v-if="!!videoDescription" style="margin-top: 20px;">
        <b style="padding-right: 20px;">{{videoDescription}}</b>
        <vs-button color="dark" type="filled" @click="copyValue(videoDescription)">Copy</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      videoID:"",
      fromValue:"",
      toValue:"",
      main:null,
      videoDescription:""
    }
  },
  methods:{
    copyValue(val) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(val);
    },
    getVideoDescription(){
      this.axios.get(`/downloadvideos?search=${this.videoID}`).then((response) => {

        this.videoDescription = response.data.searchresult;
        console.log(response)
      })
    },
    sendData() {
      this.axios.get(`/downloadvideos?minvideo=${this.fromValue}&maxvideo=${this.toValue}`).then((response) => {
        this.fromValue = "";
        this.toValue = "";
        this.main.download_link = response.data.download_link;
        this.main.download_info = response.data.download_info;
        console.log(response)
      })
    }
  },
  watch:{
    videoID(){
      this.getVideoDescription();
    }
  },
  beforeMount() {
    this.axios.get(`/downloadvideos`).then((response) => {
      this.main = response.data;
     console.log(response)
    })
  }
}
</script>