<template>
  <div>
    <AuthorsTable :users="users" :total="total"/>
<!--    <div v-for="u in users" :key="u.USERID">{{u}}</div>-->
  </div>
</template>

<script>
import AuthorsTable from "@/views/components/AuthorsTable";
export default {
  components:{
    AuthorsTable
  },
  data(){
    return {
      users:[], total:0
    }
  },
  methods:{
    getData(){
      this.axios.get("/listqualitycheck").then((response) => {
        console.log(response);
        this.total = response.data.total;
        this.users = response.data.table;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>