<template>
  <div tabindex="0"  @keydown.esc="modal=false" >
    <ProfileModal :open="modal" @closeModal="modal=false" :id="idModal"/>
    <span v-if="chats==null">
      No active chats
    </span>
    <section class="msger" v-for="(c, index) in chats" :key="index">
      <header class="msger-header">
        <div class="msger-header-title">
          <i class="fas fa-comment-alt"></i>
          <span>{{c.user_details.user_level.value}}</span>
          <span v-if="c.user_details.business">&nbsp; / Business</span>
        </div>
        <div class="msger-header-options">
<!--          <span><i class="fas fa-cog"></i></span>-->
          <vs-button color="primary" type="filled" @click="skipChat(c.CONVID)">Skip</vs-button>

        </div>
      </header>

      <main class="msger-chat">
        <div v-for="a in c.chat">
          <div class="msg left-msg" v-if="a.sender!=1">
            <div
              @click="modal=true,idModal=c.user_details.USERID.value"
              class="msg-img"
              :style="{backgroundImage:'url(' +c.user_details.profile_picture+')'}"
            ></div>

            <div class="msg-bubble">
                          <div class="msg-info">
                            <div class="msg-info-name">{{ c.user_details.user_name }}</div>
                            <div class="msg-info-time">{{a.time}}</div>
                          </div>

              <div class="msg-text">
                {{a.message}}
              </div>
            </div>
          </div>
          <div class="msg right-msg" v-else>


            <div class="msg-bubble">
              <div class="msg-info">
                <div class="msg-info-name">Admin</div>
                <div class="msg-info-time">{{a.time}}</div>
              </div>

              <div class="msg-text">
                {{a.message.replace("&lt;", "")}}
              </div>
            </div>
          </div>
        </div>



      </main>

      <form class="msger-inputarea">
        <textarea type="text" class="msger-input" placeholder="Enter your message..." v-model="c.replymessage" />
        <button  class="msger-send-btn" @click.prevent="sendMessage(c.replymessage, c.user_details.USERID.value ,c.CONVID)">Send</button>
      </form>
    </section>

  </div>
</template>

<script>
import ProfileModal from "@/views/components/ProfileModal";
export default {
  components:{
    ProfileModal
  },
  data() {
    return {
      chats:null, modal:false,
      idModal:0
    }
  },
  methods:{
    skipChat(convid) {
      this.axios.get(`/adminchat?skip=1&convid=${convid}`).then((response) => {

        location.reload();
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    sendMessage(message,  userid, convid){


      this.axios.get(`/adminchat?message=${message}&replyuserid=${userid}&convid=${convid}`).then((response) => {
        console.log(response);
        this.getData();
        location.reload();
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    getData(){
      this.axios.get(`/adminchat`).then((response) => {
        console.log(response);
        this.chats = response.data.content.conversation;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
  },
  beforeMount() {
    this.getData();
  }
}
</script>


<style lang="scss" scoped>

:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: var(--body-bg);
  font-family: Helvetica, sans-serif;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 90%;
  margin: 25px 10px;
  height: auto;
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: blue;
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}




</style>