<template>
  <div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
              style="cursor:pointer"
              @click="sortTable(key)"
              v-for="(value, key) in sortBy"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              {{ key }}
            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="u in list">
            <td>
            <img :src="u.picture" style="width:70px"/>
              <!--                <p class="text-xs text-secondary mb-0">Organization</p>-->
            </td>
            <td>
             {{ u.USERID }}
              <!--                <p class="text-xs text-secondary mb-0">Organization</p>-->
            </td>
            <td >
             {{ u.quality }}

            </td>
            <td class="text-center   ">
              <p class="text-xs font-weight-bold mb-0">{{ u.name }}</p>
            </td>
            <td class="text-center ">
              <p class="text-xs font-weight-bold mb-0 ">{{u.city}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.country}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.level}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.expire}}</p>
            </td>

            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.left}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.domain}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.vissible}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.approved}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">
                <a :href="u.enter" target="_blank">
                  <vs-button color="dark" type="filled" >Enter</vs-button>
                </a>
                </p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">
                <a :href="u.visit" target="_blank">
                  <vs-button color="dark" type="filled" >Visit</vs-button>
                </a>
                </p>
            </td>
            <!--              <td class="align-middle">-->
            <!--                <a-->
            <!--                  href="javascript:;"-->
            <!--                  class="text-secondary font-weight-bold text-xs"-->
            <!--                  data-toggle="tooltip"-->
            <!--                  data-original-title="Edit user"-->
            <!--                  >Edit</a-->
            <!--                >-->
            <!--              </td>-->
          </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data(){
    return {
    list:[],
      sortBy:{
        picture:0,
        USERID: 0,
        quality: 0,
        name: 0,
        city: 0,
        country:0,
        level: 0,
        expire: 0,
        left: 0,
        domain: 0,
        vissible: 0,
        approved: 0,
        enter: 0,
        visit:0
      }
    }
  },
  methods:{
    sortTable(param) {
      if(this.sortBy[param] == 0 || this.sortBy[param] == 2) this.sortBy[param] = 1;else this.sortBy[param] = 2;
      for(const property in this.sortBy) {
        if(property != param) this.sortBy[property] = 0;
      }
      let sorted_obj = this.list.sort((a,b) => {
        let x = a[param];
        let y = b[param];
        if(typeof a[param] == "string") {
          x = x.toLowerCase()
          y = y.toLowerCase()
        }


        if(this.sortBy[param] == 1) {
          if(x  > y ) {
            return 1;
          }
          if (x  < y ) {
            return -1;
          }
          return 0;
        }
        if(this.sortBy[param] == 2) {
          if(x > y ) {
            return -1;
          }
          if (x  < y ) {
            return 1;
          }
          return 0;
        }

      })
      this.list = sorted_obj;
      console.log(this.list)
    }
  },
  beforeMount() {
    this.axios.get("/checkpayers").then((response) => {
      console.log(response);
      this.list = response.data.list;
    })
  }
}
</script>