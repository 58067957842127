<template>
  <div >
    <div class="fixed-marks">
        <div class="fixed-marks__template">
          1
        </div>
      <div class="fixed-marks__template">
        2
      </div>
      <div class="fixed-marks__template">
        3
      </div >
      <div class="fixed-marks__template">
        4
      </div >
      <div class="fixed-marks__template">
        5
      </div>
    </div>
    <carousel :items-to-show="1" style="width:70%;margin-right: auto;">
      <slide v-for="slide in 10" :key="slide">
        <img src="../assets/img/user-profile.jpg" style="max-width:100%;">
      </slide>

      <template #addons>
        <navigation />
<!--        <pagination />-->
      </template>
    </carousel>
    <div class="py-4 container-fluid">
            <div class="mt-3 row">
    <div style="margin-top: 50px!important;" class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
      <profile-info-card
        title="Profile Information"
        description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
        :info="{
            fullName: 'Alec M. Thompson',
            mobile: '(44) 123 1234 123',
            email: 'alecthompson@mail.com',
            location: 'USA',
          }"
        :social="[
            {
              link: 'https://www.facebook.com/CreativeTim/',
              icon: faFacebook,
            },
            {
              link: 'https://twitter.com/creativetim',
              icon: faTwitter,
            },
            {
              link: 'https://www.instagram.com/creativetimofficial/',
              icon: faInstagram,
            },
          ]"
        :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
      />
    </div>
    <div style="margin-top: 50px!important;" class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
      <profile-info-card
        title="Profile Information"
        description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
        :info="{
            fullName: 'Alec M. Thompson',
            mobile: '(44) 123 1234 123',
            email: 'alecthompson@mail.com',
            location: 'USA',
          }"
        :social="[
            {
              link: 'https://www.facebook.com/CreativeTim/',
              icon: faFacebook,
            },
            {
              link: 'https://twitter.com/creativetim',
              icon: faTwitter,
            },
            {
              link: 'https://www.instagram.com/creativetimofficial/',
              icon: faInstagram,
            },
          ]"
        :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
      />
    </div>
              </div>
      </div>
<!--    <div class="py-4 container-fluid">-->
<!--      <div class="mt-3 row">-->
<!--        <div class="col-12 col-md-6 col-xl-4">-->
<!--          <div class="card h-100">-->
<!--            <div class="p-3 pb-0 card-header">-->
<!--              <h6 class="mb-0">Platform Settings</h6>-->
<!--            </div>-->
<!--            <div class="p-3 card-body">-->
<!--              <h6 class="text-xs text-uppercase text-body font-weight-bolder">-->
<!--                Account-->
<!--              </h6>-->
<!--              <ul class="list-group">-->
<!--                <li class="px-0 border-0 list-group-item">-->
<!--                  <soft-switch-->
<!--                    id="flexSwitchCheckDefault"-->
<!--                    name="email"-->
<!--                    class="ps-0 ms-auto"-->
<!--                    label-class="mb-0 text-body ms-3 text-truncate w-80"-->
<!--                    checked-->
<!--                  >Email me when someone follows me</soft-switch-->
<!--                  >-->
<!--                </li>-->
<!--                <li class="px-0 border-0 list-group-item">-->
<!--                  <soft-switch-->
<!--                    id="flexSwitchCheckDefault1"-->
<!--                    name="Email"-->
<!--                    label-class="mb-0 text-body ms-3 text-truncate w-80"-->
<!--                    class="ps-0 ms-auto"-->
<!--                  >Email me when someone answers on my post</soft-switch-->
<!--                  >-->
<!--                </li>-->

<!--                <li class="px-0 border-0 list-group-item">-->
<!--                  <soft-switch-->
<!--                    id="flexSwitchCheckDefault2"-->
<!--                    name="Email"-->
<!--                    label-class="mb-0 text-body ms-3 text-truncate w-80"-->
<!--                    class="ps-0 ms-auto"-->
<!--                    checked-->
<!--                  >Email me when someone mentions me</soft-switch-->
<!--                  >-->
<!--                </li>-->
<!--              </ul>-->
<!--              <h6-->
<!--                class="mt-4 text-xs text-uppercase text-body font-weight-bolder"-->
<!--              >-->
<!--                Application-->
<!--              </h6>-->
<!--              <ul class="list-group">-->
<!--                <li class="px-0 border-0 list-group-item">-->
<!--                  <soft-switch-->
<!--                    id="flexSwitchCheckDefault3"-->
<!--                    name="Project Launch"-->
<!--                    class="ps-0 ms-auto"-->
<!--                    label-class="mb-0 text-body ms-3 text-truncate w-80"-->
<!--                  >New launches and projects</soft-switch-->
<!--                  >-->
<!--                </li>-->
<!--                <li class="px-0 border-0 list-group-item">-->
<!--                  <soft-switch-->
<!--                    id="flexSwitchCheckDefault4"-->
<!--                    name="Product Update"-->
<!--                    class="ps-0 ms-auto"-->
<!--                    label-class="mb-0 text-body ms-3 text-truncate w-80"-->
<!--                    checked-->
<!--                  >Monthly product updates</soft-switch-->
<!--                  >-->
<!--                </li>-->
<!--                <li class="px-0 pb-0 border-0 list-group-item">-->
<!--                  <soft-switch-->
<!--                    id="flexSwitchCheckDefault5"-->
<!--                    name="Newsletter"-->
<!--                    class="ps-0 ms-auto"-->
<!--                    label-class="mb-0 text-body ms-3 text-truncate w-80"-->
<!--                  >Subscribe to newsletter</soft-switch-->
<!--                  >-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        </div>-->
<!--      </div>-->
  </div>
</template>


<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import DefaultProjectCard from "@/views/components/DefaultProjectCard";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,

      SoftSwitch,
      ProfileInfoCard,
      SoftAvatar,
      DefaultProjectCard,
      PlaceHolderCard,

  },
  beforeMount() {
    this.axios.get("/qualitycheck").then((response) => {
      console.log(response);
    })
  }
}
</script>

<style lang="scss" scoped>
.fixed-marks {
  position: fixed;
  right: 0;
}

.fixed-marks__template {
  background-color: red;
  color:white;
  padding-right: 20px;
  padding-left: 20px;
  padding: 20px;
  cursor:pointer;
  margin:1px;
  z-index: 10000;
  transition: all .7s ;

  &:hover {
    width:100px;
  }
}

</style>