<template>
  <div class="card mb-4" tabindex="0"  @keydown.esc="popupActivo=false, modal=false" >
    <ProfileModal :open="modal" @closeModal="modal=false" :id="idModal"/>
    <div class="card-header pb-0">
      <h6>Reports  {{total}}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0" style="padding: 20px!important;">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Reporter
            </th>
            <th  class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >Reported</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Reported time
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Reason
            </th>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Admin
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Solved time
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >

            </th>
          </tr>
          </thead>
          <tbody >
          <tr v-for="u in users" :key="u" :class="u.solved==0 ? 'danger-row' :'success-row'" style="border-radius: 10px;padding:10px;">
            <td style="color:black;cursor: pointer;" @click="modal=true,idModal=u.reporter">{{u.reporter}}</td>
            <td style="color:black;cursor: pointer;" @click="modal=true,idModal=u.reported">{{u.reported}}</td>
            <td style="color:black;">{{u.time}}</td>
            <td style="color:black;">
              <span style=" white-space: normal;display:block!important;width: 200px!important; word-break: break-all!important;word-break: break-word!important;hyphens: auto!important;">{{u.reason}}</span>
            </td>
            <td style="color:black;">{{u.solved_admin}}</td>
            <td style="color:black;">{{u.report_solved_date}}</td>
            <td><vs-button color="dark" type="filled" v-if="u.solved==0" @click="markSolved(u.reportid)">Solve</vs-button></td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
    <vs-popup class="holamundo"  title="Gallery" v-model:active="popupActivo" >

      <div style="display: flex;flex-direction: row;flex-wrap: wrap;row-gap: 10px;column-gap: 10px;justify-content: space-between">
        <img :key="index" :src="image" v-for="(image, index) in all_pictures" style="width:45%;" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import SoftProgress from "@/components/SoftProgress";
import ProfileModal from "@/views/components/ProfileModal";
export default {
  name: "projects-table",
  components: {
    SoftProgress,
    ProfileModal
  },
  data(){
    return {
      popupActivo:false,
      all_pictures:[],
      modal:false,
      idModal:0
    }
  },
  methods:{
    markSolved(id){
      this.axios.get(`/reports?reportid=${id}`).then((response) => {
        console.log(response);
        this.$emit('getData');
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    activatePopup(images){
      this.all_pictures = images;
      this.popupActivo=true;

    },

    copyValue(val) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(val);
    },
  },
  props:{
    users: {
      type:Array,
    },
    total:{
      type:Number
    }
  }
};
</script>

<style lang="scss" scoped>
.danger-row {
  background-image: linear-gradient(310deg, rgb(170, 61, 61) 0%, rgb(226, 93, 112) 100%);
}

.success-row {
  background-image: linear-gradient(310deg, rgb(61, 137, 77) 0%, rgb(139, 199, 63) 100%);
}

</style>
