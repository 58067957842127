<template>
  <div class="tbo_chat">
    <div style="display:flex;flex-direction:row;justify-content: space-between;padding-bottom: 20px;">
      <vs-radio v-model="radio_btn" vs-name="allnopayers" vs-value="allnopayers">No payers ( All)</vs-radio>
      <vs-radio v-model="radio_btn" vs-name="allonlypayers" vs-value="allonlypayers">Only Payers ( All )</vs-radio>
      <vs-radio v-model="radio_btn" vs-name="onlyfs" vs-value="onlyfs">Only FS!</vs-radio>
      <vs-radio v-model="radio_btn" vs-name="onlyfsnopayers" vs-value="onlyfsnopayers">Only FS! ( no payers )</vs-radio>
      <vs-radio v-model="radio_btn" vs-name="onlyfspayers" vs-value="onlyfspayers">Only FS! ( payers )</vs-radio>
    </div>
<div style="display: flex;" >
      <div class="tbo_chat__conversations">

        <vs-list style="max-height: 100vh;overflow-y:auto;">
          <vs-list-item :style="{backgroundColor: activeId?.CONVID==c.CONVID ? 'black' :'transparent'}" style="cursor: pointer;"  title="" v-for="(c, index) in conversations" :key="index" >
         <div  style="display: flex;flex-direction:row;">

           <div style="position: relative;width:80px;height:80px;" @click="activeId=c">
             <img :src="c.client_info.picture" style="width:80px;height:80px;"/>
             <div :style="{backgroundColor:c.client_info.online.color}" style="border-radius:100px;position: absolute;width:10px;height:10px;bottom:5px;right:5px;"></div>
           </div>
           <div style="display: flex;flex-direction: column;margin-left:10px;" @click="activeId=c">
               <span  style="font-weight:bold!important;display:block;width:150px;" >
               {{c.client_info.USERID}}
            </span>
             <span :style="{color:c.client_info.name.color}" style="font-weight:bold!important;display:block;width:150px;">
              {{c.client_info.name.name}} <span v-if="!c.client_info.business">, {{c.client_info.age}}</span>
            </span>
             <span  style="font-weight:bold!important;display:block;width:150px;" v-if="c.client_info.business">
              Business
            </span>
             <span  style="font-weight:bold!important;display:block;width:150px;">
              {{c.client_info.location}}
            </span>
             <span  style="font-weight:bold!important;display:block;width:150px;">
              {{c.client_info.domain}}
            </span>
             <span :style="{color:c.client_info.level.color}" style="font-weight:bold!important;display:block;width:150px;">
              {{c.client_info.level.value}}
            </span>
           </div>
           <ion-icon name="open-outline" style="cursor: pointer;" @click="openSession(c.client_info.enter_session)"></ion-icon>
         </div>
          </vs-list-item>
        </vs-list>

      </div>
<div style="width:100%;"  v-if="activeId">
    <main class="msger-chat" v-if="activeId" style="height: 70vh;overflow-y:auto;" id="messages">
      <div v-for="a in activeId.messages" >
        <div class="msg left-msg" v-if="a.align!='right'">


          <div class="msg-bubble">

            <div class="msg-text">
              {{a.message}}
            </div>
          </div>
        </div>
        <div class="msg right-msg" v-else>


          <div class="msg-bubble">

            <div class="msg-text">
              {{a.message.replace("&lt;", "")}}
            </div>
          </div>
        </div>
      </div>



    </main>
  <form class="msger-inputarea"  >
    <textarea type="text" class="msger-input" placeholder="Enter your message..." v-model="replymessage" />
    <button  class="msger-send-btn" @click.prevent="sendMessage(activeId.CONVID)">Send</button>
  </form>
  <button  class="msger-send-btn" @click.prevent="skipMessage(activeId.CONVID,'noanswer')">No answear</button>
  <button  class="msger-send-btn" @click.prevent="skipMessage(activeId.CONVID,'later')">Later</button>
</div>




    <div v-if="activeId">
      <div  style="display: flex;flex-direction:row;" >

        <div style="position: relative;width:80px;height:80px;"  >
          <img :src="activeId.my_info.picture" style="width:80px;height:80px;"/>
          <div :style="{backgroundColor:activeId.my_info.online.color}" style="border-radius:100px;position: absolute;width:10px;height:10px;bottom:5px;right:5px;"></div>
        </div>

        <div style="display: flex;flex-direction: column;margin-left:10px;" >
             <span  style="font-weight:bold!important;display:block;width:150px;" >
               {{activeId.my_info.USERID}}
            </span>
             <span :style="{color:activeId.my_info.name.color}" style="font-weight:bold!important;display:block;width:150px;">
              {{activeId.my_info.name.name}} <span v-if="!activeId.my_info.business">, {{activeId.my_info.age}}</span>
            </span>
          <span  style="font-weight:bold!important;display:block;width:150px;" v-if="activeId.my_info.business">
              Business
            </span>
          <span  style="font-weight:bold!important;display:block;width:150px;">
              {{activeId.my_info.location}}
            </span>
          <span  style="font-weight:bold!important;display:block;width:150px;">
              {{activeId.my_info.domain}}
            </span>
          <span :style="{color:activeId.client_info.level.color}" style="font-weight:bold!important;display:block;width:150px;">
              {{activeId.my_info.level.value}}
            </span>
        </div>
        <ion-icon name="open-outline" style="cursor: pointer;" @click="openSession(c.my_info.enter_session)"></ion-icon>
      </div>
    </div>
</div>
  </div>
</template>

<script>
export default {
 data(){
   return {
     conversations:[],
     activeId:null,
     replymessage:"",
     radio_btn:""
   }
 },
  methods:{
    openSession(link){
      window.open(link, "_blank");
    },
    skipMessage(id, param){
      this.axios.get(`/tbochat?convid=${id}&${param}=1`).then((response) => {
        this.replymessage = "";
        location.reload();
      })
    },
    sendMessage(id){
      if(this.replymessage == '') {alert("Empty message error");return;}
      this.axios.get(`/tbochat?message=${this.replymessage}&convid=${id}`).then((response) => {
        this.replymessage = "";
        location.reload();
      })
    },
   getData() {
      this.radio_btn = localStorage.getItem("tbochat");
     this.axios.get(`/tbochat?${this.radio_btn}=1`).then((response) => {
       this.conversations = response.data.conversations;
     })
   }
  },
  watch:{
   radio_btn(){
     localStorage.setItem("tbochat",this.radio_btn);
     this.getData();
   },
   activeId(){
     this.getData();
     // var element = document.getElementById("messages");
     // element.scrollTop = element.scrollHeight;
   }
  },
  beforeMount() {
    this.getData();
  },
}
</script>


<style lang="scss" scoped>
.tbo_chat {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  padding-right: 10px;

  &__conversations {
    display: flex;
    flex-direction: column;

  }
}

:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: var(--body-bg);
  font-family: Helvetica, sans-serif;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 90%;
  margin: 25px 10px;
  height: auto;
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px 20px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
  background: #1aff00;
  color: #fff;
  margin-top: 10px;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: blue;
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
  margin-top: 20px;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}


</style>