<template>
  <div tabindex="0"  @keydown.esc="modal=false" >
    <ProfileModal :open="modal" @closeModal="modal=false" :id="idModal"/>
    <div class="card-header pb-0">
      <h6>Bugs  {{number}}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              ID
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Domain / USERID
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Name
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Email
            </th>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Description
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Time
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
          Solved time ago
            </th>
            <th>Mark solve</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="u in table" :key="u" >
            <td style="color:black;"  >{{u.ID}} </td>
            <td style="color:black;cursor: pointer;" @click="modal=true,idModal=u.USERID"> {{u.domain}}<br>   {{u.USERID}}  </td>
            <td style="color:black;">{{u.name}} </td>
            <td style="color:black;">{{u.email}} </td>
            <td style="color:black;"> <span style=" white-space: normal;display:block!important;width: 200px!important; word-break: break-all!important;word-break: break-word!important;hyphens: auto!important;">
                {{u.description}}
              </span> </td>
            <td style="color:black;">{{u.time_ago}} </td>
            <td style="color:black;">{{u.solved_time_ago}} </td>
            <td><vs-button v-if="u.resolve_button" color="success" type="filled" @click="markDone(u.ID)">Mark done</vs-button> <span v-else>{{u.admin}}</span></td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>


<script>
import ProfileModal from "@/views/components/ProfileModal";
import Profile from "@/views/Profile";
export default {
  components:{
    ProfileModal
  },
  data() {
    return {
      table:[], number:0,
      modal:false,
      idModal:0
    }
  },
  methods:{
    markDone(id){
      this.axios.get(`/bugs?userid=${this.$route.params.id}&solvebug=${id}`).then((response) => {
        console.log(response);
        this.number = response.data.total_unsolved;
        this.table = response.data.content;

        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    getData() {
      this.axios.get(`/bugs?userid=${this.$route.params.id}`).then((response) => {
        console.log(response);
        this.number = response.data.total_unsolved;
        this.table = response.data.content;

        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    }
  },
  beforeMount() {
    this.getData()
  }
}
</script>