<template>
  <div v-if="!!main">
    <div style="display:flex;gap:10px">
      <vs-input class="inputx" placeholder="From" type="number" v-model="fromValue"/>
      <vs-input class="inputx" placeholder="To" type="number" v-model="toValue"/>
      <vs-button color="dark" type="filled" @click="sendData">Submit</vs-button>
    </div>
    <div v-if="!!main.download_link">
      <b>{{main.download_info}}</b>
      <br/>
      <a :href="main.download_link" target="_blank">
        <vs-button color="dark" type="filled" @click="sendData">Download</vs-button>
      </a>
    </div>
    <div v-if="table&&table.length">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              ID
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Description
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >

            </th>

          </tr>
          </thead>
          <tbody>
          <tr  v-for="(t, index) in table" :key="t.ID">
            <td>{{ t.ID }}</td>
            <td>{{ t.videoname }}</td>
            <td>{{ t.description }}</td>
            <td>      <vs-button color="dark" type="filled" @click="copyValue(t.description)">Copy</vs-button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      videoID:"",
      fromValue:"",
      toValue:"",
      main:null,
      videoDescription:"",
      table:[]
    }
  },
  methods:{
    copyValue(val) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(val);
    },

    sendData() {
      this.axios.get(`/listvideos?minvideo=${this.fromValue}&maxvideo=${this.toValue}`).then((response) => {
        this.fromValue = "";
        this.toValue = "";
        this.table = response.data.table;
        console.log(response)
      })
    }
  },
  watch:{
    videoID(){
      this.getVideoDescription();
    }
  },
  beforeMount() {
    this.axios.get(`/listvideos`).then((response) => {
      this.main = response.data;
      console.log(response)
    })
  }
}
</script>