<template>
  <div v-if="user" style="display:flex;flex-direction:column;max-width: 100vw;overflow: hidden;"  tabindex="0" @keydown.esc="modal=false" :style="{backgroundColor:user?.payer_warning}">
  <span v-if="total!=0">Total left: {{total}} </span>
    <h2 v-else>No more results </h2>
    <div class="big-version" v-if="total!=0" >
      <ProfileModal  :open="modal" @closeModal="modal=false" :id="idModal" />
      <span style="margin-left: auto;padding-right:20px;" v-if="user.last_rate.show">{{user.last_rate.admin}} selected rating {{user.last_rate.score}}, {{user.last_rate.time}}</span>
      <div style="display:flex;flex-direction:row;justify-content: space-between;padding-right: 40px;align-items: center;">
        <b class="text-dark" style="font-size:20px;">{{user.name}} {{ user.age ? ''+user.age: ''}} ({{user.USERID}}) <img style="width:25px;" :src="user.device_icon"/></b>
        <div style="display: flex;flex-direction: row;column-gap: 5px;padding-bottom: 10px;">
          <soft-button  v-for="(r, index) in user.rateuser" @click="rateUser(r.value)" :style="{backgroundColor:r.color}" color="#344767">
            {{r.display}}
          </soft-button>
        </div>
      </div>
    <div style="display:flex;flex-direction: row;border-top: 1px solid #c0c0c0;padding-top: 10px;column-gap: 20px;">
      <div style="display:flex;flex-direction:row;flex-wrap: wrap;height: 80vh;column-gap: 10px;overflow-y:auto;overflow-x: hidden;width:500px;min-width: 250px;row-gap: 20px;">
        <div v-for="(slide, index) in user.public_photos" :key="slide" style="display:flex;flex-direction:column;width:49%;position:relative;height:250px!important;">
<!--          @mouseover="showPopper=index" @mouseleave="showPopper=false"-->
               <img @click="modal=true,idModal=user.USERID"  :src="slide.publicpicture" style="display:block;max-width:250px;height:250px!important;" :class="{'enlargeimage':showPopper===index}"/>
          <div v-if="slide.makeprofile" @click="makeProfile(slide.submitvalue)" style="display:flex;align-items:center;justify-content:center;background-color:white;position: absolute;border-radius: 50%;width:30px;height:30px;cursor:pointer; left:3px;top:3px;">
            <i class="fa fa-camera" ></i>
          </div>
          <div v-if="slide.delete" @click="deletePhoto(slide.submitvalue)" style="display:flex;align-items:center;justify-content:center;background-color:white;position: absolute;border-radius: 50%;width:30px;height:30px;cursor:pointer;right:3px;top:3px; ">
            <i class="fa fa-trash" style="color:red;"></i>
          </div>
<!--          <soft-button color="dark" v-if="slide.makeprofile" @click="makeProfile(slide.submitvalue)" style="margin-top: 10px;">Make profile photo</soft-button>-->
<!--               <soft-button color="dark" v-if="slide.delete" @click="deletePhoto(slide.submitvalue)" style="margin-top: 10px;">Delete photo</soft-button>-->
             </div>
      </div>
      <div style="display:flex;flex-direction: column;margin-left: 0px;flex-grow: 1;">
                  <strong>{{user.last_login}}</strong>
                <div style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">City, country</span>
                  <Tooltip :tooltipText="user.warning_location!=true ? '' : user.ip_location" style="margin-left: 200px;">
                  <strong :class="{'warning-text':user.warning_location==true}" >{{ user.location }}</strong>
                  </Tooltip>
                </div>
        <div style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
          <span style="width:200px;">User domain</span>
          <Tooltip style="margin-left: 200px;">
            <strong  >{{ user.what_domain }}</strong>
          </Tooltip>
        </div>
                <div v-if="user.sex" :style="{backgroundColor: user.sex=='Female' ?'#dd00ff' : '#002AFFFF'}" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;color:white">Sex</span>
                  <Tooltip  tooltipText="" style="margin-left: 200px;">
                    <strong  style="color:white">{{ user.sex }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.nationality" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Nationality</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.nationality }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.email" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Email</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.email }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.register_date" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Register date</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.register_date }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.about_me!==false" style="display: flex;flex-direction: row;padding:1px 10px;padding-top: 20px;width:100%;">
                  <span style="width:200px;">About me</span>

        <!--            <span>{{user.about_me.split('&lt;br&gt;').join('\n')}}</span>-->
             <div style="margin-left: 280px;width:100%;">
                      <textarea style="max-height:300px;border-radius: 5px;padding:10px;irection:rtl;width:100%;
            display:block;
            line-height:1.5;
            padding:15px 15px 30px;
            border-radius:3px;
            border:1px solid #F7E98D;
            font:13px Tahoma, cursive;
            transition:box-shadow 0.5s ease;
            box-shadow:0 4px 6px rgba(0,0,0,0.1);
            font-smoothing:subpixel-antialiased;
            background:-o-linear-gradient(#F9EFAF, #F7E98D);
            background:-ms-linear-gradient(#F9EFAF, #F7E98D);
            background:-moz-linear-gradient(#F9EFAF, #F7E98D);
            background:-webkit-linear-gradient(#F9EFAF, #F7E98D);
            background:linear-gradient(#F9EFAF, #F7E98D);
            " v-model="user.about_me">

                    </textarea>
               <soft-button color="info" @click="updateAboutMe(user.about_me)" style="margin-top: 10px;">Update</soft-button>
             </div>
                </div>
        <div v-if="user.want_to_visit.length" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
          <span style="width:200px;">Want to visit</span>
          <div style="display:flex;flex-direction: row;flex-wrap: wrap;width:400px;margin-left: 200px;">
            <strong v-for="w in user.want_to_visit">{{w.wanttovisitlocation}}</strong>
          </div>
        </div>
                <div v-if="user.phone" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Phone</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.phone }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.user_level" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Level</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.user_level }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.business" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Account type</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.business }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.user_domain" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Domain</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.user_domain }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.offers" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Offers</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.offers }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.needs" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Needs</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.needs }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.user_membership" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Membership</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  >{{ user.user_membership }}</strong>
                  </Tooltip>
                </div>
                <div v-if="user.accounts_per_ip" style="display: flex;flex-direction: row;width:100%;padding:1px 10px;padding-top: 20px;">
                  <span style="width:200px;">Accounts per ip</span>
                  <Tooltip tooltipText="" style="margin-left: 200px;">
                    <strong  style="color:red;">{{ user.accounts_per_ip }}</strong>
                  </Tooltip>
                </div >
        <div style="margin-left:auto; padding-right: 50px;" v-if="user.reverse_sex_possible" ><soft-button color="primary" @click="changeSex">Change sex</soft-button></div>
                <div style="margin-left:auto; padding-right: 50px;margin-top:20px;"><soft-button color="primary" @click="restoreUser">Restore</soft-button></div>
        <div style="margin-left:auto; padding-right: 50px;display:flex;justify-content:center;margin-top: 20px"><soft-button color="primary" @click="handleMessageModal(user.USERID)">Send Message</soft-button></div>
        <div class="card-body px-0 pt-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table align-items-center justify-content-center mb-0">
              <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Action
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Time
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(h, index) in user.history" :key="index">
              <td >
                <h6 class="mb-0 text-sm">{{ h.change }}</h6>
              </td>
                <td >
                  <h6 class="mb-0 text-sm">{{ h.time }}</h6>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
    </div>
    <div class="small-version">
      <div style="display: flex;flex-direction: row;column-gap: 5px;padding-bottom: 10px;overflow:auto;padding:10px;">
        <soft-button class="small-version__btn"  v-for="r in user.rateuser" @click="rateUser(r.value)" :style="{backgroundColor:r.color}" color="#344767">
          {{r.display}}
        </soft-button>
      </div>
      <carousel :items-to-show="1">
        <slide v-for="(slide,index) in user.public_photos" :key="slide">
          <div style="position: relative;">
          <img :src="slide.publicpicture" style="display:block;max-width:350px;height:180px!important;" :class="{'enlargeimage':showPopper===index}"/>
          <div v-if="slide.makeprofile" @click="makeProfile(slide.submitvalue)" style="display:flex;align-items:center;justify-content:center;background-color:white;position: absolute;border-radius: 50%;width:30px;height:30px;cursor:pointer; left:3px;top:3px;">
            <i class="fa fa-camera" ></i>
          </div>
          <div v-if="slide.delete" @click="deletePhoto(slide.submitvalue)" style="display:flex;align-items:center;justify-content:center;background-color:white;position: absolute;border-radius: 50%;width:30px;height:30px;cursor:pointer;right:3px;top:3px; ">
            <i class="fa fa-trash" style="color:red;"></i>
          </div>
          </div>
        </slide>

        <template #addons>
<!--          <navigation />-->
          <pagination />
        </template>
      </carousel>
      <div class="sections__container" v-if="user.last_login">
        <span class="sections__container__title">Name, age</span>
        <span class="sections__container__text">{{user.name}}, {{user.age}}  <img style="width:20px;" :src="user.device_icon"/></span>
      </div>
      <div class="sections__container" v-if="user.last_login">
        <span class="sections__container__title">Last Login</span>
        <span class="sections__container__text">{{user.last_login}}</span>
      </div>
      <div class="sections__container" v-if="user.location">
        <span class="sections__container__title">City, country</span>
        <span class="sections__container__text"> <Tooltip :tooltipText="user.warning_location!=true ? '' : user.ip_location">
                  <strong :class="{'warning-text':user.warning_location==true}" >{{ user.location }}</strong>
                  </Tooltip></span>
      </div>
      <div class="sections__container" v-if="user.sex">
        <span class="sections__container__title">Sex</span>
        <span class="sections__container__text">{{user.sex}}</span>
      </div>
      <div class="sections__container" v-if="user.nationality">
        <span class="sections__container__title">Nationality</span>
        <span class="sections__container__text">{{user.nationality}}</span>
      </div>
      <div class="sections__container" v-if="user.email">
        <span class="sections__container__title">Email</span>
        <span class="sections__container__text">{{user.email}}</span>
      </div>
      <div class="sections__container" v-if="user.register_date">
        <span class="sections__container__title">Register date</span>
        <span class="sections__container__text">{{user.register_date}}</span>
      </div>
      <div class="sections__container" v-if="user.about_me">
        <span class="sections__container__title">About me</span>
        <div v-if="user.about_me!==false" style="display: flex;flex-direction: row;padding:1px 10px;padding-top: 20px;width:100%;">


          <!--            <span>{{user.about_me.split('&lt;br&gt;').join('\n')}}</span>-->
          <div style="width:100%;">
                      <textarea style="max-height:300px;border-radius: 5px;padding:10px;irection:rtl;width:100%;
            display:block;
            line-height:1.5;
            padding:15px 15px 30px;
            border-radius:3px;
            border:1px solid #F7E98D;
            /*font:13px Tahoma, cursive;*/
            transition:box-shadow 0.5s ease;
            box-shadow:0 4px 6px rgba(0,0,0,0.1);
            font-smoothing:subpixel-antialiased;
            background:-o-linear-gradient(#F9EFAF, #F7E98D);
            background:-ms-linear-gradient(#F9EFAF, #F7E98D);
            background:-moz-linear-gradient(#F9EFAF, #F7E98D);
            background:-webkit-linear-gradient(#F9EFAF, #F7E98D);
            background:linear-gradient(#F9EFAF, #F7E98D);
            " v-model="user.about_me">
                    </textarea>
            <soft-button color="info" @click="updateAboutMe(user.about_me)" style="margin-top: 10px;">Update</soft-button>
          </div>
          </div>
      </div>
      <div class="sections__container" v-if="user.want_to_visit.length">
        <span class="sections__container__title">Want to visit</span>
        <span class="sections__container__text" v-for="w in user.want_to_visit">{{w.wanttovisitlocation}}</span>
      </div>
      <div class="sections__container" v-if="user.phone">
        <span class="sections__container__title">Phone</span>
        <span class="sections__container__text">{{user.phone}}</span>
      </div>
      <div class="sections__container" v-if="user.user_level">
        <span class="sections__container__title">User level</span>
        <span class="sections__container__text">{{user.user_level}}</span>
      </div>
      <div class="sections__container" v-if="user.business">
        <span class="sections__container__title">Account type</span>
        <span class="sections__container__text">{{user.business}}</span>
      </div>

      <div class="sections__container" v-if="user.user_domain">
        <span class="sections__container__title">User domain</span>
        <span class="sections__container__text">{{user.user_domain}}</span>
      </div>

      <div class="sections__container" v-if="user.offers">
        <span class="sections__container__title">Offers</span>
        <span class="sections__container__text">{{user.offers}}</span>
      </div>

      <div class="sections__container" v-if="user.needs">
        <span class="sections__container__title">Needs</span>
        <span class="sections__container__text">{{user.needs}}</span>
      </div>

      <div class="sections__container" v-if="user.user_membership">
        <span class="sections__container__title">Memebership</span>
        <span class="sections__container__text">{{user.user_membership}}</span>
      </div>
      <div class="sections__container" v-if="user.accounts_per_ip">
        <span class="sections__container__title">Accounts per IP</span>
        <span class="sections__container__text">{{user.accounts_per_ip}}</span>
      </div>
      <div style="margin-left:auto; padding-right: 50px;margin-right:auto;display:flex;justify-content:center;" v-if="user.reverse_sex_possible" ><soft-button color="primary" @click="changeSex">Change sex</soft-button></div>
      <div style="margin-left:auto; padding-right: 50px;margin-right:auto;display:flex;justify-content:center;margin-top: 20px"><soft-button color="primary" @click="restoreUser">Restore</soft-button></div>
      <div style="margin-left:auto; padding-right: 50px;margin-right:auto;display:flex;justify-content:center;margin-top: 20px"><soft-button color="primary" @click="handleMessageModal(user.USERID)">Send Message</soft-button></div>

    </div>
    <AdminMessage :open="messageModal.opened" :id="messageModal.id" @closeModal="handleMessageModal"/>
  </div>
</template>


<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { defineComponent } from "vue";
import Popper from "vue3-popper";
import SoftSwitch from "@/components/SoftSwitch.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import AdminMessage from "@/components/AdminMessage";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import 'vue3-carousel/dist/carousel.css';
import ProfileModal from "@/views/components/ProfileModal";

import DefaultProjectCard from "@/views/components/DefaultProjectCard";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard";
import  Tooltip  from "@/components/Tooltip.vue";
import user from "@/views/User";
import SoftButton from "@/components/SoftButton";
export default {
  components: {
    Tooltip,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    SoftSwitch,
    ProfileInfoCard,
    SoftAvatar,
    DefaultProjectCard,
    PlaceHolderCard,
    Popper, SoftButton,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ProfileModal,
    AdminMessage
  },
  data(){
    return {
      user:null,
      showPopper:false,
      modal:false,
      idModal:0,
      total:0,
      messageModal:{
        opened:false,
        id:null
      },
    }
  },
  methods:{
    handleMessageModal(id) {
      if(!id || this.messageModal.opened) {
        this.messageModal.opened = false;
        this.messageModal.id = null;
      }
      else {
        this.messageModal.opened = true;
        this.messageModal.id = id;
      }
    },
    async changeSex() {
      await this.axios.get(`/qualitycheck?reversesex=${this.user.USERID}`);
      location.reload();
    },
    async rateUser(rating) {
      await this.axios.get(`/qualitycheck?ratescore=${rating}&USERID=${this.user.USERID}`);
      location.reload();
    },
    async makeProfile(id){
      await this.axios.get(`/qualitycheck?makeprofilephoto=${id}`);
      this.getData();
    },
    async deletePhoto(id){
      await this.axios.get(`/qualitycheck?deletephoto=${id}`);
      this.getData();
    },
    getData(){
      this.axios.get("/qualitycheck").then((response) => {
        if(response.data.shownoresults == true) this.$router.push('/dashboard')
        console.log(response);
        this.user = response.data.user;
        this.total = response.data.total;
        this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', "\n");

        console.log(this.user.about_me);
      })
    },
    updateAboutMe(aboutme) {
      this.axios.get(`/qualitycheck?USERID=${this.user.USERID}&updateaboutme=${aboutme == '' ? 'empty' : aboutme.replace(/\n/g, "<br>")}`).then((response) => {
        location.reload();
      })
    },
    restoreUser() {
      this.axios.get(`/qualitycheck?USERID=${this.user.USERID}&restore=1`).then((response) => {
        this.getData();
      })
    }
  },
  beforeMount() {
    this.getData()
  }
}
</script>

<style lang="scss" >

.profile_picture:hover {

  z-index:10000;
  transition: all 0.5s;
  transform: scale(1.7);
}
.enlargeimage {
  z-index:10000;
  transition: all 0.5s;
  transform: scale(2.5);
}
.fixed-marks {
  position: fixed;
  right: 0;
}

.fixed-marks__template {
  background-color: #0d6efd;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
text-align: center;
  width:100px;
  height:45px;
  cursor:pointer;
  margin:1px;
  z-index: 10000;
  transition: all .7s ;

  &:hover {
    transform: scale(1.1);
  }


}

.warning-text {
  color:red!important;
}

.small-version {
  display: none;
}

@media only screen and (max-width: 1200px) {
 .big-version {
   display: none;
 }
  .small-version {
    display: block;
  }
  //body, html,div{
  //  overflow: hidden!important;
  //
  //}
}


.sections__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  background: rgb(255, 255, 255);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;

}
.sections__container__title{
  display: block;
  color:#63AA3A;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}
.sections__container__text{
  display: block;
  height: auto;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-left: 10px;
  word-wrap: break-word;
  font-size: 15px;
}
.carousel__slide--visible {
  transform: rotateY(0);
}

@media only screen and (max-width: 600px) {
  .small-version__btn {
    padding:10px!important;
  }
}
</style>