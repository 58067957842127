<template>
  <div>
    <div class="card-header pb-0">
      <h6>Reports  {{total}}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Page
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Phone
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              City, country
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Domain
            </th>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              IP
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Time
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >

            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="u in table" :key="u" >
            <td style="color:black;">{{u.page_title}} </td>
            <div class="d-flex px-2">
              <div>
                <img

                  v-if="u.apptype"
                  :src="u.apptype"
                  class="avatar  me-2"
                  alt="spotify"
                />
              </div>
              <div class="my-auto">
                <h6 class="mb-0 text-sm" >{{u.phonetype}}</h6>
              </div>
            </div>
            <td style="color:black;">{{u.city}} {{u.country}}</td>
            <td style="color:black;">{{u.domain}}</td>
            <td style="color:black;">{{u.ip}}</td>
            <td style="color:black;">{{u.timeago}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      table:[], total:0
    }
  },
  methods:{
    getData() {
      this.axios.get(`/fullusertrack?userid=${this.$route.params.id}`).then((response) => {
        console.log(response);
        this.total =response.data.total;
        this.table = response.data.content;

        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    }
  },
  beforeMount() {
    this.getData()
  }
}
</script>