<template>
  <div>
    <span v-if="routes.length == 0">
      No results
    </span>
    <div v-else>

      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
            style="cursor:pointer"

            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            File
          </th>
          <th
            style="cursor:pointer"

            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Time
          </th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="u in routes">

          <td >
           {{u.file}}

          </td>
          <td >
            {{u.time}}

          </td>

        </tr>


        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      routes:[]
    }
  },
  beforeMount() {
    this.axios.get(`/routesspeed`).then((response) => {
      console.log(response);
      this.routes= response.data.content;
    })
  }
}
</script>