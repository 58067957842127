<template lang="html">
  <div class="centerx" v-if="user"   >
    <vs-popup class="holamundo"   fullscreen title="" :active.sync="popupActivo" @close="$emit('closeModal')">
      <vs-button radius color="dark" @click="getData" size="small" type="filled" icon="replay" style="position: absolute;left:50px;top:18px;"></vs-button>
      <div style="display:flex;flex-direction: row;">
        <div style="display:flex;flex-display:row;gap:20px;flex-wrap: wrap;flex: 0 0 700px!important">
          <img v-for="(i, index) in user.public_pictures" :src="i.publicpicture" style="width:300px;height:300px;border-radius: 10px;"/>


        </div>


      <div style="display: flex;flex-direction: column;justify-content: start;min-width: 500px;">
        <div style="display:flex;flex-direction:row;">
        <span class="sections__container__text" style="padding-bottom:20px;">{{user ? user.name +' ' + user.age.value : ''}}</span>
          <div style="margin-left:10px;">
            <img :src="user.visible" style="width:20px;height:20px;"/>
            <img :src="user.approved" style="width:20px;height:20px;margin-left: 10px;"/>
            <img :src="user.devicetype" style="width:20px;height:20px;margin-left: 10px;"/>

          </div>
          <span class="sections__container__text" style="padding-bottom:20px;">{{user.userid}}</span>
<div style="max-height: 30px;display:flex;margin-left: auto;">
  <vs-button  style="margin-left: auto;color:black;" size="small;"  :color="user.verified_level.color" type="filled">{{ user.verified_level.value }}</vs-button>

</div>
          <!--          <span class="sections__container__text" >Level: <span :style="{color:user.verified_level.color}">{{user.verified_level.value}}</span></span>-->
        </div>
        <div style="display:flex;flex-direction: row;gap:20px;border-bottom: 1px solid black;padding-bottom:5px;">
          <div v-for="act in user.useractivity.filter(a=>a.show)">
            <span class="sections__container__text"><ion-icon :name="act.icon" :style="{color:act.color}"></ion-icon>&nbsp;{{act.title}} ({{act.value}})</span>


          </div>

        </div>
        <div class="sections__container" >

          <span class="sections__container__text">{{user.online}}</span>
        </div>
        <div class="sections__container" v-if="user.domain">
          <span class="sections__container__title" :style="{color:user.sex_color}">Domain </span>
          <span class="sections__container__text">{{user.domain}}</span>
        </div>
        <div class="sections__container" v-if="user.level">
          <span class="sections__container__title" :style="{color:user.sex_color}">Level </span>
          <span class="sections__container__text" :style="{color:user.level.color}">{{user.level.value}} <br/> {{user.level.expire_date}}</span>
        </div>
        <div class="sections__container" v-if="user.business">
                    <span class="sections__container__title" :style="{color:user.sex_color}">Account type </span>
                    <span class="sections__container__text">{{user.business}}</span>
                  </div>
        <div class="sections__container" >
          <span class="sections__container__title" :style="{color:user.sex_color}">City, country</span>
          <span class="sections__container__text" :style="{color:user.location.color}">{{user.location.value}}</span>
          <span class="sections__container__text" >{{user.location.real}}</span>
        </div>

        <div class="sections__container" v-if="user.wanttovisit.show">
          <span class="sections__container__title" :style="{color:user.sex_color}">Want to visit</span>
          <div style="display: flex;flex-direction:column;flex-width: wrap;">
            <span v-for="im in user.wanttovisit.value" class="sections__container__text">
{{im.wanttovisitlocation}}
            </span>

          </div>
        </div>
        <div class="sections__container" v-if="user.moreuserinfo.filter(o=>o.text).length">
          <span class="sections__container__title" :style="{color:user.sex_color}">More user info</span>
          <div style="display: flex;flex-direction:row;flex-width: wrap;">
            <span class="sections__container__text" v-for="info in user.moreuserinfo.filter(o=>o.text)">{{info.text}} </span>
          </div>
        </div>
        <div class="sections__container" v-if="user.offersandneeds.filter(o=>o.offer==true).length">
          <span class="sections__container__title" :style="{color:user.sex_color}">Offers</span>
          <div style="display: flex;flex-direction:row;flex-width: wrap;">
            <span class="sections__container__text" v-for="offer in user.offersandneeds.filter(o=>o.offer==true)">{{offer.title}} </span>
          </div>
        </div>
        <div class="sections__container" v-if="user.offersandneeds.filter(o=>o.need==true).length">
          <span class="sections__container__title" :style="{color:user.sex_color}">Needs</span>
          <div style="display: flex;flex-direction:row;flex-width: wrap;">
            <span class="sections__container__text" v-for="need in user.offersandneeds.filter(o=>o.need==true)">{{need.title}} </span>
          </div>
        </div>
        <div class="sections__container" style="border-bottom: 1px solid black;" v-if="user.aboutme.show">
          <span class="sections__container__title" :style="{color:user.sex_color}">About me</span>
          <span class="sections__container__text">{{user.aboutme.value}}</span>
        </div>
        <div style="margin-top:30px;"></div>
        <div class="sections__container" v-if="user.email.show"  >
          <span class="sections__container__title" :style="{color:user.sex_color}">Email</span>
          <span class="sections__container__text">{{user.email.value}}</span>
        </div>
        <div class="sections__container" v-if="user.phone.show">
          <span class="sections__container__title" :style="{color:user.sex_color}">Phone</span>
          <span class="sections__container__text">{{user.phone.value}}</span>
        </div>
       <div> <vs-button color="danger" type="filled" @click="enterSession">Enter session</vs-button></div>
<!--        <div class="sections__container" v-if="user.last_login">-->
<!--          <span class="sections__container__title">Last Login</span>-->
<!--          <span class="sections__container__text">{{user.last_login}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.location">-->
<!--          <span class="sections__container__title">City, country</span>-->
<!--          <span class="sections__container__text"> <Tooltip :tooltipText="user.warning_location!=true ? '' : user.ip_location">-->
<!--                  <strong :class="{'warning-text':user.warning_location==true}" >{{ user.location }}</strong>-->
<!--                  </Tooltip></span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.sex">-->
<!--          <span class="sections__container__title">Sex</span>-->
<!--          <span class="sections__container__text">{{user.sex}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.nationality">-->
<!--          <span class="sections__container__title">Nationality</span>-->
<!--          <span class="sections__container__text">{{user.nationality}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.email">-->
<!--          <span class="sections__container__title">Email</span>-->
<!--          <span class="sections__container__text">{{user.email}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.register_date">-->
<!--          <span class="sections__container__title">Register date</span>-->
<!--          <span class="sections__container__text">{{user.register_date}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.about_me">-->
<!--          <span class="sections__container__title">About me</span>-->
<!--          <div v-if="user.about_me!==false" style="display: flex;flex-direction: row;padding:1px 10px;padding-top: 20px;width:100%;">-->


<!--            &lt;!&ndash;            <span>{{user.about_me.split('&lt;br&gt;').join('\n')}}</span>&ndash;&gt;-->
<!--            <div style="width:100%;">-->
<!--                      <textarea style="max-height:300px;border-radius: 5px;padding:10px;irection:rtl;width:100%;-->
<!--            display:block;-->
<!--            line-height:1.5;-->
<!--            padding:15px 15px 30px;-->
<!--            border-radius:3px;-->
<!--            border:1px solid #F7E98D;-->
<!--            /*font:13px Tahoma, cursive;*/-->
<!--            transition:box-shadow 0.5s ease;-->
<!--            box-shadow:0 4px 6px rgba(0,0,0,0.1);-->
<!--            font-smoothing:subpixel-antialiased;-->
<!--            background:-o-linear-gradient(#F9EFAF, #F7E98D);-->
<!--            background:-ms-linear-gradient(#F9EFAF, #F7E98D);-->
<!--            background:-moz-linear-gradient(#F9EFAF, #F7E98D);-->
<!--            background:-webkit-linear-gradient(#F9EFAF, #F7E98D);-->
<!--            background:linear-gradient(#F9EFAF, #F7E98D);-->
<!--            " v-model="user.about_me">-->
<!--                    </textarea>-->
<!--              <soft-button color="info" @click="updateAboutMe(user.about_me)" style="margin-top: 10px;">Update</soft-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.want_to_visit.length">-->
<!--          <span class="sections__container__title">Want to visit</span>-->
<!--          <span class="sections__container__text" v-for="w in user.want_to_visit">{{w.wanttovisitlocation}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.phone">-->
<!--          <span class="sections__container__title">Phone</span>-->
<!--          <span class="sections__container__text">{{user.phone}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.user_level">-->
<!--          <span class="sections__container__title">User level</span>-->
<!--          <span class="sections__container__text">{{user.user_level}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.business">-->
<!--          <span class="sections__container__title">Account type</span>-->
<!--          <span class="sections__container__text">{{user.business}}</span>-->
<!--        </div>-->

<!--        <div class="sections__container" v-if="user.user_domain">-->
<!--          <span class="sections__container__title">User domain</span>-->
<!--          <span class="sections__container__text">{{user.user_domain}}</span>-->
<!--        </div>-->

<!--        <div class="sections__container" v-if="user.offers">-->
<!--          <span class="sections__container__title">Offers</span>-->
<!--          <span class="sections__container__text">{{user.offers}}</span>-->
<!--        </div>-->

<!--        <div class="sections__container" v-if="user.needs">-->
<!--          <span class="sections__container__title">Needs</span>-->
<!--          <span class="sections__container__text">{{user.needs}}</span>-->
<!--        </div>-->

<!--        <div class="sections__container" v-if="user.user_membership">-->
<!--          <span class="sections__container__title">Memebership</span>-->
<!--          <span class="sections__container__text">{{user.user_membership}}</span>-->
<!--        </div>-->
<!--        <div class="sections__container" v-if="user.accounts_per_ip">-->
<!--          <span class="sections__container__title">Accounts per IP</span>-->
<!--          <span class="sections__container__text">{{user.accounts_per_ip}}</span>-->
<!--        </div>-->
      </div>
<!--      <p>{{id}}-->
<!--       {{user}}-->

<!--      </p>-->
      </div>
    </vs-popup>
  </div>
</template>

<script>

export default {
  props:{
    open:{
      type:Boolean,
    },
    id:{},

  },
  data(){
    return {
      popupActivo:false,
      user:null
    }
  },
  methods:{
    enterSession(){
      window.open(this.user.enterweb.value);
    },
    getData(){
      this.axios.get(`/viewprofile?user=${this.id}`).then((response) => {
        console.log(response);
        this.user = response.data.viewprofile;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    test(){
      alert(1)
    }
  },
  watch:{
    id(){
      this.getData();
    },
    open(){

      this.popupActivo = this.open;
    },
    popupActivo() {
      if(this.popupActivo == false) this.$emit("closeModal");
    }
  }
}
</script>


<style lang="scss" scoped>

.profile_picture:hover {

  z-index:10000;
  transition: all 0.5s;
  transform: scale(1.7);
}
.enlargeimage {
  z-index:10000;
  transition: all 0.5s;
  transform: scale(2.5);
}
.fixed-marks {
  position: fixed;
  right: 0;
}

.fixed-marks__template {
  background-color: #0d6efd;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  text-align: center;
  width:100px;
  height:45px;
  cursor:pointer;
  margin:1px;
  z-index: 10000;
  transition: all .7s ;

  &:hover {
    transform: scale(1.1);
  }


}

.warning-text {
  color:red!important;
}

.small-version {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .big-version {
    display: none;
  }
  .small-version {
    display: block;
  }
  //body, html,div{
  //  overflow: hidden!important;
  //
  //}
}


.sections__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  background: rgb(255, 255, 255);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;

}
.sections__container__title{
  display: block;
  color:#63AA3A;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
  //width:500px;
}
.sections__container__text{
  display: block;
  height: auto;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-left: 10px;
  word-wrap: break-word;
  font-size: 15px;

}
.carousel__slide--visible {
  transform: rotateY(0);
}
</style>