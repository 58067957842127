<template>
  <div class="card mb-4" tabindex="0"  @keydown.esc="popupActivo=false, modal=false,idModal=null">
    <ProfileModal :open="modal" @closeModal="modal=false, idModal=null" :id="idModal"/>
    <div class="card-header pb-0">
      <h6>Users</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                UserId
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >

              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Track User
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Picture
              </th>
              <th  class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >Pay</th>
              <th  class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >Stats</th>
              <th  class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >LVL</th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Location
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Active
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Email
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"

              >
                Enter session
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Phone
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(u, index) in users" :key="u.USERID.value" @click="activeHover=index" :style="{backgroundColor:activeHover==index ?  '#d5d5d5' : u.color==true ? '#8deaef' : null}" >
              <td style="display: flex;">

                <vs-button :color="u.USERID.color" type="filled" @click="copyValue(u.USERID.value)" size="small" style="min-width: 60px!important">{{u.USERID.value}}</vs-button>
<!--                <p class="text-sm font-weight-bold mb-0" style="display: inline-block;padding:10px;cursor: pointer;" :style="{backgroundColor:u.USERID.color}" @click="copyValue(u.USERID.value)">{{u.USERID.value}}</p>-->
                <div style="margin-left: 10px;width:20px!important;height:20px!important;width:20px!important;">
                  <img
                    v-if="u.USERID.icon"
                    :src="u.USERID.icon"
                    class="avatar avatar-sm rounded-circle me-2"
                    alt="spotify"
                    style="width:20px!important;height:20px!important;width:20px!important;"
                  />
                  <p>{{u.domain.value}}</p>
                </div>
                <!--                <div class="d-flex px-2">-->
<!--                  <div>-->
<!--                    <img-->
<!--                      src="../../assets/img/small-logos/logo-invision.svg"-->
<!--                      class="avatar avatar-sm rounded-circle me-2"-->
<!--                      alt="spotify"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div class="my-auto">-->
<!--                    <h6 class="mb-0 text-sm">Spotify</h6>-->
<!--                  </div>-->
<!--                </div>-->
              </td>
              <td>
                <div class="d-flex px-2">
                  <div style="margin-left: auto;margin-right: auto;width:20px!important;height:20px!important;">
                    <img
                      style="width:17px!important;height:17px!important;"
                      v-if="u.social_registerd.icon"
                      :src="u.social_registerd.icon"
                      class="avatar avatar-sm rounded-circle me-2"
                      alt="spotify"
                    />
                  </div>
                  <!--                                    <div class="my-auto">-->
                  <!--                                      <h6 class="mb-0 text-sm">Spotify</h6>-->
                  <!--                                    </div>-->
                </div>
              </td>
              <td>
                <h6 class="mb-0 text-sm" style="margin-left: auto;text-align: center;" >
                  <vs-button @click="toRoute(u.USERID.value)" v-if="u.user_actions.value!=0" :color="u.user_actions.value!=0 ? 'dark' : 'warning'" type="gradient" icon="query_stats" size="small">{{u.user_actions.value!=0 ? u.user_actions.value : "" }}</vs-button>
                  <vs-button  color="warning" type="gradient" icon="content_copy" size="small" style="margin-top: 20px;" @click="copyValue(u.token)">Token</vs-button>
                  <vs-button  color="danger" type="gradient" icon="send" size="small" style="margin-top: 20px;" @click="handleMessageModal(u.USERID.value)">Message</vs-button>
                </h6>
              </td>
              <td>
                <div class="d-flex px-2">
                                    <div>
                                      <img
                                        @click="activatePopup(u.pictures.all_pictures)"
                                        v-if="u.pictures.profile_picture"
                                        :src="u.pictures.profile_picture"
                                        class="avatar  me-2"
                                        alt="spotify"
                                      />
                                    </div>
                                   <div class="my-auto">
                                      <h6 class="mb-0 text-sm" style="cursor: pointer" :style="{color:u.name.color}" @click="modal=true,idModal=u.USERID.value">{{ u.name.value }} {{u.name.age}}</h6>
                                    </div>
                                  </div>
              </td>

              <td>
                <h6 class="mb-0 text-sm" style="margin-left: auto;text-align: center;" :style="{color:u.level.color}">{{ u.level.value}}</h6>
                <h6 class="mb-0 text-sm" style="margin-left: auto;text-align: center;" >{{ u.level.expire_date}}</h6>
              </td>
              <td>
                <div>

                  <img :src="u.approved" style="width: 15px;height:15px;cursor: pointer;" @click="confirmApprove(u.USERID.value)"/>
                  <img :src="u.visible" style="width: 15px;height:15px;margin-left: 10px;cursor:pointer;" @click="confirmVisibility(u.USERID.value)" />
                </div>
              </td>
              <td>
                <vs-button :color="u.verified_level.color" style="color:black!important;" type="filled">{{ u.verified_level.value }}</vs-button>
              </td>

              <td>
                <h6 class="mb-0 text-sm" :style="{color:u.location.color}">{{ u.location.value}}</h6>
                <h6 class="mb-0 text-sm">{{ u.location.real}}</h6>
              </td>
              <td class="">
                <p class="text-sm font-weight-bold mb-0" :style="{color:u.online.color}"
                >{{u.online.value}}</p>

                <!--                <div class="d-flex align-items-center justify-content-center">-->
<!--                  <span class="me-2 text-xs font-weight-bold">60%</span>-->
<!--                  <div>-->
<!--                    <soft-progress-->
<!--                      color="info"-->
<!--                      variant="gradient"-->
<!--                      :percentage="60"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
              </td>
              <td>
                <h6 class="mb-0 text-sm"  >{{ u.email.value}}</h6>

              </td>
              <td>
                <h6 class="mb-0 text-sm" @click="newTab(u.enterweb.value)" ><vs-button color="rgb(134, 4, 98)" type="filled" size="small">Enter</vs-button>
                </h6>

              </td>
              <td>
                <h6 class="mb-0 text-sm">{{ u.phone.value}}</h6>

              </td>
<!--              <td class="align-middle">-->
<!--                <button class="btn btn-link text-secondary mb-0">-->
<!--                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>-->
<!--                </button>-->
<!--              </td>-->

              <td>
                <h6 v-if="u.delete_possible" class="mb-0 text-sm" style="margin-left: auto;text-align: center;cursor:pointer;" @click="confirmDel(u.USERID.value)">
                  <vs-button color="danger" type="gradient" icon="delete"></vs-button>
                </h6>
              </td>



            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <AdminMessage :open="messageModal.opened" :id="messageModal.id" @closeModal="handleMessageModal"/>
    <vs-popup class="holamundo"  title="Gallery" v-model:active="popupActivo" >

      <div style="display: flex;flex-direction: row;flex-wrap: wrap;row-gap: 10px;column-gap: 10px;justify-content: space-between">
        <img :key="index" :src="image" v-for="(image, index) in all_pictures" style="width:45%;" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import SoftProgress from "@/components/SoftProgress";
import ProfileModal from "@/views/components/ProfileModal";
import AdminMessage from "@/components/AdminMessage";

export default {
  name: "projects-table",
  components: {
    SoftProgress,
    ProfileModal,
    AdminMessage
  },
  data(){
    return {
      popupActivo:false,
      all_pictures:[],
      modal:false,
      idModal:0,
      activeHover:-1,
      messageModal:{
        opened:false,
        id:null
      },
    }
  },
  methods:{
    newTab(page){
        window.open(page)
    },
    activatePopup(images){
      this.all_pictures = images;
      this.popupActivo=true;

    },
    toRoute(r){
      this.$router.push("/trackuser/"+r);
    },
    confirmDel(id){
      var answer = window.confirm("Delete user?");
      if (answer) {
        //some code
        this.axios.get(`/users?delete=${id}`).then((response) => {
          console.log(response);
          this.$emit('getData');
          // this.user = response.data.user;
          // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
          // console.log(this.user.about_me);
        })
      }
      else {
        //some code
        // alert(2)
      }
    },
    confirmApprove(id){
      return;
      var answer = window.confirm("Confirm action");
      if (answer) {
        //some code
        this.axios.get(`/users?approveuserid=${id}`).then((response) => {
          console.log(response);
          this.$emit('getData');
          // this.user = response.data.user;
          // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
          // console.log(this.user.about_me);
        })
      }
      else {
        //some code
        // alert(2)
      }
    },
    confirmVisibility(id){
      return;
      var answer = window.confirm("Confirm action");
      if (answer) {
        //some code
        this.axios.get(`/users?makevissible=${id}`).then((response) => {
          console.log(response);
          this.$emit('getData');
          // this.user = response.data.user;
          // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
          // console.log(this.user.about_me);
        })
      }
      else {
        //some code
        // alert(2)
      }
    },
    handleMessageModal(id) {
      if(!id || this.messageModal.opened) {
        this.messageModal.opened = false;
        this.messageModal.id = null;
      }
      else {
        this.messageModal.opened = true;
        this.messageModal.id = id;
      }
    },
    copyValue(val) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(val);
    },
  },
  props:{
    users: {
      type:Array
    }
  }
};
</script>

<style>
td {
  color:black;
}
</style>
