<template>
  <div @keydown.esc=" modal=false,idModal=null" tabindex="0">
    <ProfileModal :open="modal" @closeModal="modal=false, idModal=null" :id="idModal"/>
    <div style="display: flex;flex-direction: row;align-items: start;padding-right: 10px;">
      <ul class="leftx" style="list-style: none">
        <li class="modelx">
          Level select:
        </li>
        <li>
          <vs-radio v-model="level" vs-name="1" vs-value="1">1</vs-radio>
        </li>
        <li>
          <vs-radio v-model="level" vs-name="2" vs-value="2">2</vs-radio>
        </li>
        <li>
          <vs-radio v-model="level" vs-name="3" vs-value="3">3</vs-radio>
        </li>
        <li>
          <vs-radio v-model="level" vs-name="4" vs-value="4">4</vs-radio>
        </li>
        <li>
          <vs-radio  v-model="level" vs-name="5" vs-value="5">5</vs-radio>
        </li>
      </ul>
      <ul class="leftx" style="list-style: none">
        <li class="modelx">
          Gender select:
        </li>
        <li>
          <vs-radio v-model="gender" vs-name="female" vs-value="female">Female</vs-radio>
        </li>
        <li>
          <vs-radio v-model="gender" vs-name="male" vs-value="male">Male</vs-radio>
        </li>

      </ul>
    </div>
    <b>{{profiles}}</b>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
              style="cursor:pointer"
              @click="sortTable(key)"
              v-for="(value, key) in sortBy"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              {{ key }}
            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="u in list">
            <td @click="modal=true,idModal=u.userid">
              <img :src="u.picture" style="width:70px"/>
              <!--                <p class="text-xs text-secondary mb-0">Organization</p>-->
            </td>
            <td>
              {{ u.userid }}
              <!--                <p class="text-xs text-secondary mb-0">Organization</p>-->
            </td>
            <td >
              <p style="cursor: pointer;" @click="modal=true,idModal=u.userid">{{ u.name }}</p>

            </td>
            <td class="text-center   ">
              <p class="text-xs font-weight-bold mb-0">{{ u.age }}</p>
            </td>
            <td class="text-center ">
              <p class="text-xs font-weight-bold mb-0 ">{{u.get_msg}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.sent_msg}}</p>
            </td>
            <td :style="u.scam_warn==true  ? 'background-color:red' : null">
              <p class="text-xs font-weight-bold mb-0 " >{{u.scam_warn}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.city}}</p>
            </td>

            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.country}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 "><vs-input v-model="u.quality" @change="updateQuality(u.userid, u.quality)" style="max-width: 20px"/></p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.domain}}</p>
            </td>

            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.vissible}}</p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">{{u.approved}}</p>
            </td>
<!--            <td>-->
<!--              <p class="text-xs font-weight-bold mb-0 ">-->
<!--                <a :href="u.enter" target="_blank">-->
<!--                  <vs-button color="dark" type="filled" >Enter</vs-button>-->
<!--                </a>-->
<!--              </p>-->
<!--            </td>-->
            <td>
              <p class="text-xs font-weight-bold mb-0 ">
                <a :href="u.enter" target="_blank">
                  <vs-button color="dark" type="filled" >Enter</vs-button>
                </a>
              </p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0 ">
                <a :href="u.visit" target="_blank">
                  <vs-button color="dark" type="filled" >Visit</vs-button>
                </a>
              </p>
            </td>

            <!--              <td class="align-middle">-->
            <!--                <a-->
            <!--                  href="javascript:;"-->
            <!--                  class="text-secondary font-weight-bold text-xs"-->
            <!--                  data-toggle="tooltip"-->
            <!--                  data-original-title="Edit user"-->
            <!--                  >Edit</a-->
            <!--                >-->
            <!--              </td>-->
          </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import ProfileModal from "@/views/components/ProfileModal";
export default {
  components:{
    ProfileModal
  },
  data(){
    return {
      profiles:0,
      level:1,
      list:[],
      gender:"male",
      activeColor:"red",
      sortBy:{
        picture: 0,
        userid: 0,
        name: 0,
        age: 0,
        get_msg: 0,
        sent_msg: 0,
        scam_warn: 0,
        city: 0,
        country: 0,
        quality: 0,
        domain: 0,
        vissible: 0,
        approved: 0,
        enter: 0,
        visit: 0,
      },
      modal:false,
      idModal:false
    }
  },
  methods:{
    sortTable(param) {
      if(this.sortBy[param] == 0 || this.sortBy[param] == 2) this.sortBy[param] = 1;else this.sortBy[param] = 2;
      for(const property in this.sortBy) {
        if(property != param) this.sortBy[property] = 0;
      }
      let sorted_obj = this.list.sort((a,b) => {
        let x = a[param];
        let y = b[param];
        if(typeof a[param] == "string") {
          x = x.toLowerCase()
          y = y.toLowerCase()
        }


        if(this.sortBy[param] == 1) {
          if(x  > y ) {
            return 1;
          }
          if (x  < y ) {
            return -1;
          }
          return 0;
        }
        if(this.sortBy[param] == 2) {
          if(x > y ) {
            return -1;
          }
          if (x  < y ) {
            return 1;
          }
          return 0;
        }

      })
      this.list = sorted_obj;
      console.log(this.list)
    },
    updateQuality(id, quality){
      alert("UserId: " + id + " Quality: " + quality);
      this.axios.get(`/peoplecheck?rate=${quality}&userid=${id}`).then((response) => {
        console.log(response);
        this.getData();
      })
    },
    getData() {
      this.axios.get(`/peoplecheck?level=${this.level}&gender=${this.gender}`).then((response) => {
        console.log(response);
        this.list = response.data.table;
        this.profiles = response.data.number;
      })
    }
  },
  watch:{
    level() {
      localStorage.setItem("sortLevel", this.level);
        this.getData();
    },
    gender() {
      localStorage.setItem("sortGender", this.gender);
      this.getData();
    }
  },
  beforeMount() {
    this.level = localStorage.getItem("sortLevel")
    this.gender = localStorage.getItem("sortGender")
    this.getData();
  }
}
</script>