<template>
  <div tabindex="0"  @keydown.esc="modal=false" >
    <ProfileModal :open="modal" @closeModal="modal=false" :id="idModal"/>
    <div class="card mb-4" tabindex="0"  >
      <div class="card-header pb-0">
        <h6>Contacts {{total}}</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
            <tr>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                ID/ USERID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                email
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                domain
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                message
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7 ps-2"
              >
               Reply
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                solved_admin
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="t in contacts" :key="t.USERID" :class="t.solved_admin!='' ? 'success-row' : ''">
              <td>{{t.ID}} / <span @click="modal=true,idModal=t.USERID">{{t.USERID}}</span></td>
              <td> <vs-tooltip :text="t.USERID" position="left" ><span @click="copyValue(t.USERID)" style="cursor:pointer;">{{t.name}}</span> </vs-tooltip></td>
              <td><img :src="t.device_icon" style="width:25px;"/> {{t.email}}</td>
              <td>{{t.date}}</td>
              <td>{{t.domain}} </td>

              <td>
                <div v-if="t.solved_admin==''">
                <vs-textarea   style="width:300px;" v-model="t.text"/>
                <div style="display:flex;width:300px;justify-content: space-between;">
                  <vs-button color="primary" type="filled" style="" @click="replyContact(t.ID, t.text)">Reply</vs-button>
                  <vs-button color="danger" type="filled" style="" @click="markDone(t.ID)" >Mark done</vs-button>
                </div>
                  </div>
                <p v-if="t.reply" style=" white-space: normal;display:block!important;width: 200px!important; word-break: break-all!important;word-break: break-word!important;hyphens: auto!important;"> {{t.reply}}</p>
              </td>
              <td >
                <span style="font-weight:bold; white-space: normal;display:block!important;width: 200px!important; word-break: break-all!important;word-break: break-word!important;hyphens: auto!important;">{{t.subject}}</span>
                <br/>
               <span style=" white-space: normal;display:block!important;width: 200px!important; word-break: break-all!important;word-break: break-word!important;hyphens: auto!important;">
                {{t.message}}
              </span>

              </td>
              <td >{{t.solved_admin}}<br/> {{t.solved_time}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactTable from "@/views/components/ContactTable";
import ProfileModal from "@/views/components/ProfileModal";
export default {
  components:{
    ContactTable,
    ProfileModal
  },
  data(){
    return {
      contacts:[], number:0,
      idModal:"",
      modal:false,

    }
  },
  methods:{
    copyValue(val) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(val);
    },
    markDone(id){
      this.axios.get(`/contact?done=${id}`).then((response) => {

        // this.contacts = response.data.content;
        this.getData();

        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    replyContact(id, text){
      this.axios.get(`/contact?idformessage=${id}&message=${text}`).then((response) => {

        this.contacts = response.data.content;
        location.reload();
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },
    getData(){
      this.axios.get(`/contact`).then((response) => {
        this.total = response.data.total_unsolved
        this.contacts = response.data.content;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.success-row {
  background-image: linear-gradient(310deg, rgb(61, 137, 77) 0%, rgb(139, 199, 63) 100%);
}

</style>