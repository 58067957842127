<template>
  <div>
    <Table :info="info" @getData="getData"></Table>
  </div>
</template>

<script>
import Table from "./components/TrackTable";
import SoftInput from "@/components/SoftInput";
import SoftButton from "@/components/SoftButton";
export default {
  components:{
    Table,
    SoftInput, SoftButton
  },
  data(){
    return {
      info:null,
    }
  },
  methods: {
    getData(){
      this.axios.get(`/trackhomepage`).then((response) => {
        console.log(response);
        this.info = response.data;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },

  }, beforeMount() {
    this.getData();
  }
}
</script>