<template>
  <div class="check-video" v-if="!!video">
    <div style="margin-bottom: 20px;">
      <vs-button color="danger" type="filled" style="margin-right: 20px;" @click="rateVideo(2)">Danger</vs-button>

      <vs-button color="success" type="filled" style="margin-left: 20px;"  @click="rateVideo(1)">Success</vs-button>
    </div>
    <b style="margin-bottom: 10px;display:block;">{{video.description}}</b>
    <video  loop  controls playsinline>
      <source :src="video.link" type="video/mp4">
<!--      <source src="movie.ogg" type="video/ogg">-->
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  data(){
    return {
      video:null
    }
  },
  methods:{
    rateVideo(rating){
      this.axios.get(`/checkvideo?quality=${rating}&videoid=${this.video.videoid}`).then((response) => {
        this.video = response.data.video;
        location.reload();

      })
    }
  },
  beforeMount() {
    this.axios.get(`/checkvideo`).then((response) => {
      this.video = response.data.video;
      console.log(this.video)
      console.log(response);
    })
  }
}
</script>

<style lang="scss" scoped>
.check-video {
  max-width: 80vw;
  text-align: center;

  b {
    margin-left: auto;
    margin-right: auto;
  }

  video {
    display: block;
    margin-left:auto;
    margin-right:auto;
    max-height: 80vh;
  }
}
</style>