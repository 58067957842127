<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0" v-if="description!=false">Description</h6>
        </div>
        <div class="col-md-4 text-end">
          <a :href="action.route">
<!--            <i-->
<!--              class="text-sm fas fa-user-edit text-secondary"-->
<!--              data-bs-toggle="tooltip"-->
<!--              data-bs-placement="top"-->
<!--              :title="action.tooltip"-->
<!--            ></i>-->
          </a>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <p class="text-sm" v-if="description!=false">
        {{ description }}
      </p>
      <hr class="my-4 horizontal gray-light" />
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0" v-if="info.fullName !=false">
          <strong class="text-dark">Full Name:</strong> &nbsp;
          {{ info.fullName }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.mobile !=false">
          <strong class="text-dark">Mobile:</strong> &nbsp; {{ info.mobile }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.email !=false">
          <strong class="text-dark">Email:</strong> &nbsp; {{ info.email }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.location !=false">
          <strong class="text-dark" :class="{'warning-text':info.warning==true}">Location:</strong> &nbsp;
          {{ info.location }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Online:</strong> &nbsp;
          {{ info.online }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.level !=false">
          <strong class="text-dark">Level:</strong> &nbsp;
          {{ info.level }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.sex !=false">
          <strong class="text-dark">Sex:</strong> &nbsp;
          {{ info.sex }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.age !=false">
          <strong class="text-dark">Age:</strong> &nbsp;
          {{ info.age }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" >
          <strong class="text-dark">Register date:</strong> &nbsp;
          {{ info.register_date }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.nationality !=false" >
          <strong class="text-dark">Nationality:</strong> &nbsp;
          {{ info.nationality }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.domain !=false" >
          <strong class="text-dark">Domain:</strong> &nbsp;
          {{ info.domain }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.membership !=false" >
          <strong class="text-dark">Membership:</strong> &nbsp;
          {{ info.membership }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0"  >
          <strong class="text-dark">Account type:</strong> &nbsp;
          {{ info.account_type }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0" v-if="info.want_to_visit.length > 0" >
          <strong class="text-dark">Want to visit:</strong> &nbsp;
          <span v-for="(w,index) in info.want_to_visit" :key="index">{{w.wanttovisitlocation}}&nbsp;</span>
        </li>
        <li class="text-sm border-0 list-group-item ps-0"  >
          <strong class="text-dark">Services:</strong> &nbsp;
          <span v-for="(s,index) in info.services" :key="index">{{s.name}} - Offer({{s.offer}}) - Need({{s.need}})&nbsp;</span>
        </li>
<!--        <li class="pb-0 border-0 list-group-item ps-0">-->
<!--          <strong class="text-sm text-dark">Social:</strong> &nbsp;-->
<!--          <a-->
<!--            v-for="({ icon, link }, index) of social"-->
<!--            :key="index"-->
<!--            class="py-0 mb-0 btn-simple ps-1 pe-2"-->
<!--            :href="link"-->
<!--          >-->
<!--            <font-awesome-icon :icon="icon" />-->
<!--          </a>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ProfileInfoCard",
  components: {
    FontAwesomeIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    membership: {
      type: String,
      default: "",
    },
    domain: {
      type: String,
      default: "",
    },
    services:{
      type:Array,
      default:[]
    },
    warning:{
      type:Boolean,
      default: false,
    },
    online: {
      type: String,
      default: "",
    },
    account_type: {
      type: String,
      default: "",
    },
    age: {
      type: String,
      default: "",
    },
    level:{
      type: String,
      default:"1"
    },
    natioality: {
      type: String,
      default: "",
    },
    register_date: {
      type: String,
      default: "",
    },
    sex: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    want_to_visit: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      location: String,
      default: () => {},
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
};
</script>


<style scoped>
.warning-text {
  color:red!important;
}


</style>