<template>
  <div class="card mb-4" tabindex="0"  @keydown.esc="modal=false" >
    <ProfileModal :open="modal" @closeModal="modal=false" :id="idModal"/>
    <div class="card-header pb-0">
      <h6>Quality list {{total}}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                UserID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Time
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Rate score
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Admin name
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u in users">
              <td  @click="modal=true,idModal=u.USERID" style="cursor:pointer;">
                <div class="d-flex px-2 py-1">
                  <div>
                    <soft-avatar
                      :img="u.picture"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{u.name}}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{u.USERID}}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ u.time }}</p>
<!--                <p class="text-xs text-secondary mb-0">Organization</p>-->
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge :style="{background:u.color}" style="color:black!important;" variant="gradient" size="sm" v-if="u.rate_score"
                  >{{ u.rate_score }}</soft-badge
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{u.admin_current_name}}</span
                >
              </td>
<!--              <td class="align-middle">-->
<!--                <a-->
<!--                  href="javascript:;"-->
<!--                  class="text-secondary font-weight-bold text-xs"-->
<!--                  data-toggle="tooltip"-->
<!--                  data-original-title="Edit user"-->
<!--                  >Edit</a-->
<!--                >-->
<!--              </td>-->
            </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import ProfileModal from "@/views/components/ProfileModal";

export default {
  name: "authors-table",
  props:{
    users: {
      type:Object,
      default:()=>{}
    },
    total:{
      type:Number
    }
  },
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      modal:false,
      idModal:0
    };
  },
  components: {
    SoftAvatar,
    SoftBadge,
    ProfileModal
  },
};
</script>
