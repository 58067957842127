<template>
  <div>
    <span v-if="routes.length == 0">
      No results
    </span>
    <div v-else>
      <p>Total: {{total}}</p>
      <p>Per minute: {{per_minute}}</p>

      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th
            style="cursor:pointer"

            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            File
          </th>
          <th
            style="cursor:pointer"

            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Count
          </th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="u in routes">

          <td >
            {{u.file}}

          </td>
          <td >
            {{u.count}}

          </td>

        </tr>


        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      routes:[],
      total:0,
      per_minute:0
    }
  },
  beforeMount() {
    this.axios.get(`/toproutes`).then((response) => {
      console.log(response);
      this.routes= response.data.table;
      this.total = response.data.total;
      this.per_minute = response.data.per_minute
    })
  }
}
</script>