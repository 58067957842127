<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Prices list</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Country
            </th>

            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Old price
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              New price
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(u, index) in prices" :class="{done_price:!u.discount_possible||u.old_price}">
            <td >
                <span class="text-secondary text-xs font-weight-bold"
                      style="margin-left: 15px;color:black!important;"
                >{{u.country}}</span
                >
            </td>
            <td >

              <div class="mb-3" v-if="u.discount_possible">
                <input
                  @input="updatePrice(u, index)"
                  v-model="u.old_price"

                  placeholder="Old price"
                />
              </div>
            </td>
            <td >
              <div class="mb-3" v-if="u.discount_possible">
             <span @click="copyValue(u.new_price)" style="color:black;cursor: pointer;" v-if="u.new_price">{{u.new_price}}</span>
              </div>
            </td>
            <!--              <td class="align-middle">-->
            <!--                <a-->
            <!--                  href="javascript:;"-->
            <!--                  class="text-secondary font-weight-bold text-xs"-->
            <!--                  data-toggle="tooltip"-->
            <!--                  data-original-title="Edit user"-->
            <!--                  >Edit</a-->
            <!--                >-->
            <!--              </td>-->
          </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import SoftInput from "@/components/SoftInput";

export default {
  name: "authors-table",
  props:{
    prices: {
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
    };
  },
  components: {
    SoftAvatar,
    SoftBadge,
    SoftInput
  },
  methods: {
    copyValue(price) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(price);
    },
    async updatePrice(u, index) {
      console.log("updatePrice")
      this.axios.get(`/calculatediscount?tier=${u.tier}&price=${u.old_price}&ID=${u.ID}`).then((response) => {
        console.log(response);
        this.prices[index].new_price = response.data.newprice.new_price;
      })

    }
  }
};
</script>

<style lang="scss" scoped>
  .done_price {
    background-color: #676767;
  }
</style>