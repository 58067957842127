<template>
  <div>
    <div style="display: flex;flex-direction: row;justify-content: space-between;">
   <div style="width:500px;">
<div style="display:flex;">
<div>
  <soft-input
    :value="exact"
    @input="event => exact = event.target.value"
    id="search-exact"
    type="text"
    placeholder="Exact Search"
    name="search-exact"
  />
</div>
<div style="margin-left: 20px;">
  <vs-button color="primary" type="border" icon="search" @click="getData('search1', exact)"></vs-button>
</div>
</div>
     <div style="display:flex;">
       <div>
         <soft-input
           :value="simple"
           @input="event => simple = event.target.value"
           id="search-exact"
           type="text"
           placeholder="Search"
           name="search-exact"
         />
       </div>
       <div style="margin-left: 20px;">
         <vs-button color="primary" type="border" icon="search" @click="getData('search2', simple)"></vs-button>
       </div>
     </div>
   </div>
     <div style="display: flex;flex-direction: column;align-items: start;padding-right: 10px;">
       <vs-checkbox v-model="payers"  >Only Payers</vs-checkbox>
       <vs-checkbox v-model="expiredpayers"  style="margin-top: 10px;">Expired payers</vs-checkbox>
       <vs-checkbox v-model="onlygirls" style="margin-top: 10px;" >Only Girls</vs-checkbox>
       <vs-checkbox v-model="onlytbo" style="margin-top: 10px;" >Only Tbo</vs-checkbox>
       <vs-checkbox v-model="onlyfs" style="margin-top: 10px;" >Only FS</vs-checkbox>
       <vs-checkbox v-model="notfs" style="margin-top: 10px;" >Not FS</vs-checkbox>
       <vs-checkbox v-model="onlydeleted" style="margin-top: 10px;" >Only Deleted</vs-checkbox>

     </div>
    </div>
    <Table :users="users" @getData="getData"></Table>
  </div>
</template>

<script>
import Table from "./components/ProjectsTable";
import SoftInput from "@/components/SoftInput";
import SoftButton from "@/components/SoftButton";
export default {
  components:{
    Table,
    SoftInput, SoftButton
  },
  data(){
    return {
      users:[],
      exact:"", simple:"",
      payers:false,
      expiredpayers:false,
      onlygirls:false,
      onlytbo:false,
      onlyfs:false,
      notfs:false,
      onlydeleted:false
    }
  },
  methods: {
    getData(type, text){
      this.axios.get(`/users?${type}=${text}&onlypayers=${this.payers ? 1 : 0}&payedbefore=${this.expiredpayers ? 1 : 0}&onlygirls=${this.onlygirls ? 1 : 0}&onlytbo=${this.onlytbo ? 1 : 0}&onlyfs=${this.onlyfs ? 1 : 0}&notfs=${this.notfs ? 1 : 0}&onlydeleted=${this.onlydeleted ? 1 : 0}`).then((response) => {
        console.log(response);
        this.users = response.data.users;
        // this.user = response.data.user;
        // this.user.about_me = this.user.about_me.replaceAll('&lt;br&gt;', '<br/>');
        // console.log(this.user.about_me);
      })
    },

  },
  watch:{
    payers(){
      this.getData();
    },
    expiredpayers(){
      this.getData();
    },
    onlytbo(){
      this.getData();
    },
    onlygirls(){
      this.getData();
    },
    onlyfs(){
      this.getData();
    },
    notfs(){
      this.getData();
    },
    onlydeleted(){
      this.getData();
    }
  },
  beforeMount() {
    if(this.$route.params.type == 'payers') this.payers = true;
    this.getData();
  },

}
</script>