import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Logout from "@/views/Logout.vue";
import QualityCheck from "@/views/QualityCheck";
import User from "@/views/User.vue";
import ListQualityCheck from "@/views/ListQualityCheck"
import Tools from "@/views/Tools.vue";
import Simulator from "@/views/Simulator.vue";
import Users from "@/views/Users.vue";
import Track from "@/views/Track.vue";
import Contact from "@/views/Contact.vue";
import Reports from "@/views/Reports.vue";
import Messages from "@/views/Messages";
import TrackUser from "@/views/TrackUser.vue";
import Bugs from "@/views/Bugs.vue";
import AdminChat from "@/views/AdminChat.vue";
import TboChat from "@/views/TboChat.vue";
import AddStats from "@/views/AddStats";
import CheckVideo from "@/views/CheckVideo";
import DownloadVideo from "@/views/DownloadVideo";
import ListVideos from "@/views/ListVideos"
import CheckPayers from "@/views/CheckPayers";

import LastPayers from "@/views/LastPayers"
import PeopleCheck from "@/views/PeopleCheck";
import PhpErrors from "@/views/PhpErrors"
import RouteSpeed from "@/views/RouteSpeed"
import TopRoutes from "@/views/TopRoutes";
import BackFromEmail from "@/views/BackFromEmail";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/qualitycheck",
    name: "QualityCheck",
    component: QualityCheck,
  },
  {
    path: "/user/:id?",
    name: "User",
    component: User,
  },
  {
    path: "/listqualitycheck",
    name: "ListQualityCheck",
    component: ListQualityCheck,
  },
  {
    path: "/tools",
    name: "Tools",
    component: Tools,
  },
  {
    path: "/simulator",
    name: "Simulator",
    component: Simulator,
  },
  {
    path: "/users/:type?",
    name: "Users",
    component: Users,
  },
  {
    path: "/track",
    name: "Track",
    component: Track,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path:"/messages",
    name:"Messages",
    component: Messages,
  },
  {
    path:"/trackuser/:id",
    name:"TrackUser",
    component: TrackUser,
  },
  {
    path:"/bugs",
    name:"Bugs",
    component: Bugs,
  },
  {
    path:"/adminchat",
    name:"AdminChat",
    component: AdminChat,
  },
  {
    path:"/tbochat",
    name:"TBOChat",
    component: TboChat,
  },
  {
    path:"/adstats",
    name:"AddStats",
    component: AddStats,
  },
  {
    path:"/check-video",
    name:"CheckVideo",
    component: CheckVideo,
  },
  {
    path:"/download-video",
    name:"DownloadVideo",
    component: DownloadVideo,
  },
  {
    path:"/list-videos",
    name:"ListVideos",
    component: ListVideos,
  },
  {
    path:"/check-payers",
    name:"CheckPayers",
    component: CheckPayers,
  },
  {
    path:"/lastpayers",
    name:"LastPayers",
    component: LastPayers,
  },
  {
    path:"/peoplecheck",
    name:"PeopleCheck",
    component: PeopleCheck,
  },
  {
    path:"/phperrors",
    name:"PhpErrors",
    component: PhpErrors,
  },
  {
    path:"/routesspeed",
    name:"RouteSpeed",
    component: RouteSpeed,
  },
  {
    path:"/toproutes",
    name:"TopRoutes",
    component: TopRoutes,
  },
  {
    path:"/backfromemail",
    name:"BackfromEmail",
    component: BackFromEmail,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to,from,next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/sign-in'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !localStorage.getItem('adminid')&&!localStorage.getItem('admintoken')) {
    next({name: 'Sign In'});
  }
  if(publicPages.includes(to.path)&& localStorage.getItem('adminid')&&localStorage.getItem('admintoken'))
    next({name: 'Dashboard'});
  next()
});

export default router;
