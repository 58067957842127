<template>
  <div class="card mb-4" v-if="info">
    <div class="card-header pb-0">
      <h6>{{ info.total_track }} devices</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Phone
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              City, country
            </th>
            <th  class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >Time</th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Number
            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(u,index) in info.track" :key="index">
            <td style="display: flex;">
              <div style="margin-left: 10px;">
                <img
                  v-if="u.apptype"
                  :src="u.apptype"
                  class="avatar avatar-sm rounded-circle me-2"
                  alt="spotify"
                  style="width:20px!important;height:20px!important"
                />
              </div>
              <h6 class="mb-0 text-sm"  >{{ u.phonetype}}</h6>
            </td>

            <td >
              <h6 class="mb-0 text-sm"  >{{ u.city}}, {{u.country}}</h6>
            </td>

            <td >
              <h6 class="mb-0 text-sm"  >{{ u.time}}</h6>
            </td>
            <td>
             <span>{{ u.number }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import SoftProgress from "@/components/SoftProgress";

export default {
  name: "projects-table",
  components: {
    SoftProgress,
  },
  props:{
    info: {
      type:Object
    }
  }
};
</script>
